import { useState } from 'react';
import { DropdownSearch } from '@/components/dropdown-search/dropdown-search';
import {
  CompaniesFilterNameType,
  CompaniesFilterType,
  CompanyLocation,
  CompanyLocationWithName,
  FilterOption,
} from '../../types';
import { CompaniesLocationFilter } from '../companies-filters/companies-location-filter';
import { MultiSelect } from '@/components/multi-select/multi-select';
import { Button } from '@/components/button/Button';
import { IconButton } from '@/components/icon-button/icon-button';
import { mapAndSortCompanySizes } from '../companies-filters/data-parser';
import './companies-filters-mobile.scss';
import { Chip } from '@/components/chip/chip';

type Props = {
  locations: CompanyLocation[];
  sizes: string[];
  nonSelectedTags: { name: string }[];
  industries: string[];
  selectedLocations: CompanyLocationWithName[];
  selectedFilters: CompaniesFilterType;
  handleClose?: () => void;
  saveMobileFilters: (newFilters: CompaniesFilterType, newLocations: CompanyLocationWithName[]) => void;
};

export const CompaniesFiltersMobile = ({
  locations,
  sizes,
  nonSelectedTags,
  industries,
  selectedLocations,
  selectedFilters,
  handleClose,
  saveMobileFilters,
}: Props) => {
  const [localFilters, setLocalFilters] = useState<CompaniesFilterType>(selectedFilters);
  const [localSelectedLocations, setLocalSelectedLocations] = useState<
  CompanyLocationWithName[]
  >(selectedLocations);

  const handleLocalSelectLocation = (filterValue: CompanyLocationWithName) => {
    setLocalSelectedLocations((prev) => [...prev, filterValue]);
  };

  const handleLocalSearchSelect = (
    name: CompaniesFilterNameType,
    option: FilterOption,
  ) => {
    setLocalFilters((prev) => ({
      ...prev,
      [name]: [...prev[name], option],
    }));
  };

  const handleLocalFilterChange = (
    name: CompaniesFilterNameType,
    options: FilterOption[],
  ) => {
    setLocalFilters((prev) => ({
      ...prev,
      [name]: options,
    }));
  };

  const handleApplyFilters = () => {
    saveMobileFilters(localFilters, localSelectedLocations);
    handleClose?.();
  };

  const handleResetFilters = () => {
    setLocalFilters({
      industry: [],
      sizes: [],
      tags: [],
    });
    setLocalSelectedLocations([]);
  };

  return (
    <div className="companies-filters-mobile">
      <div className="companies-filters-mobile__wrapper">
        <div className="companies-filters-mobile__top">
          <div className="companies-filters-mobile__header">All Filters</div>
          <div className="companies-filters-mobile__content">
            <div className="companies-filters-mobile__filters">
              <CompaniesLocationFilter
                allLocations={locations}
                selectedLocations={localSelectedLocations}
                handleLocationSelect={handleLocalSelectLocation}
              />

              {localSelectedLocations.length > 0 && (
                <div className="mobile-job-filters__location-tags">
                  {localSelectedLocations.map((location) => (
                    <Chip
                      key={`location-${location.name}`}
                      label={location.name as string}
                      type="location"
                      handleClose={() => {
                        setLocalSelectedLocations((prev) => prev.filter((loc) => loc.name !== location.name));
                      }}
                    />
                  ))}
                </div>
              )}

              <div className="companies-filters__search-field">
                <DropdownSearch
                  selectedOption=""
                  options={nonSelectedTags}
                  handleItemSelect={(item) => handleLocalSearchSelect('tags', { name: item, value: item })}
                  placeholder="Search tags"
                  name="tag-search"
                  id="tag-search"
                  inputIcon="bi bi-search"
                  size="medium"
                  canAddItems={false}
                  data-testid="companies-filters-tag-search"
                  showIcon={false}
                  withMultipleSelection
                  openOnClick
                  listWidth="full"
                />
              </div>

              {localFilters.tags.length > 0 && (
                <div className="mobile-job-filters__location-tags">
                  {localFilters.tags.map((tag) => (
                    <Chip
                      key={`location-${tag.name}`}
                      label={tag.name as string}
                      type="location"
                      handleClose={() => {
                        setLocalFilters((prev) => ({
                          ...prev,
                          tags: prev.tags.filter((t) => t.name !== tag.name),
                        }));
                      }}
                    />
                  ))}
                </div>
              )}

              <div
                className="companies-filters__filter"
                data-testid="companies-filters-industry-filter"
              >
                <MultiSelect
                  labelType="list"
                  handleFiltering={(options: FilterOption[]) => handleLocalFilterChange('industry', options)}
                  placeholder="Industry"
                  selected={localFilters.industry}
                  options={industries.map((industry) => ({
                    name: industry,
                    value: industry,
                  }))}
                  size="full"
                />
              </div>
              <div
                className="companies-filters__filter"
                data-testid="companies-filters-company-size-filter"
              >
                <MultiSelect
                  labelType="list"
                  handleFiltering={(options: FilterOption[]) => handleLocalFilterChange('sizes', options)}
                  placeholder="Company Size"
                  selected={localFilters.sizes}
                  options={mapAndSortCompanySizes(sizes) || []}
                  size="full"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="companies-filters-mobile__bottom">
          <Button
            mode="primary"
            outlined
            size="fill"
            label="Reset"
            icon="bi bi-arrow-repeat"
            handleClick={handleResetFilters}
          />
          <Button
            mode="primary"
            size="fill"
            label="Apply All"
            icon="bi bi-funnel"
            handleClick={handleApplyFilters}
          />
        </div>
        <div className="companies-filters-mobile__close-button">
          <IconButton
            mode="unset"
            icon="bi bi-x"
            size="medium"
            handleClick={handleClose}
          />
        </div>
      </div>
    </div>
  );
};
