import { User } from '@auth0/auth0-react';
import { useContext, useEffect } from 'react';
import './tracker-job-tab.scss';
import { ModalContext } from '@/components/modal/modal-provider';
import { InfoModal } from '@/domains/generic/modals';
import { Job } from '../../types';
import { TrackerJobTile } from '../tracker-job-tile/tracker-job-tile';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { addJob, deleteJob } from '@/services/api/job';
import { Button } from '@/components/button/Button';
import { ManuallyAddJob } from '../manual-job-add/manual-add-job';
import { AddJobButton } from '../add-job-button/add-job-button';
import { JobTabStates } from '@/domains/core/tracker-data';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';

type Props = {
  jobs: Job[];
  user?: User;
  companyName: string;
  companyId: string;
  companyLinkedIn: string;
  companyLogo: string;
  changeTab: (tab: string, state?: string) => void;
  tabState?: JobTabStates;
  revalidate: () => void;
};

export const TrackerJobTab = ({
  jobs,
  user,
  companyName,
  companyId,
  companyLinkedIn,
  companyLogo,
  changeTab,
  revalidate,
  tabState,
}: Props) => {
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const { trackEvent } = useAnalytics();
  const { addToast }: AddToastType = useContext(ToastContext);

  const handleJobDelete = async (jobID: string) => {
    trackEvent('Job Deleted', user);
    await deleteJob(jobID);
    closeModal();
    revalidate();
  };

  const openAddJob = () => {
    const newSearchParams = new URLSearchParams(window.location.search);

    if (newSearchParams.has('manuallyAddJob')) {
      newSearchParams.append('manuallyAddJob', 'true');
    }

    window.history.replaceState(null, '', `?${newSearchParams.toString()}`);
    changeTab('Jobs', 'addJobManually');
  };

  const handleAddJob = async (job: Omit<Job, 'id'>) => {
    try {
      const response = await addJob(job);

      if (response.error) {
        throw new Error(response.error);
      }

      revalidate();
      changeTab('Jobs');
      trackEvent('Tracker page: Saved job manually');
      addToast(
        {
          type: 'success',
          message: 'Job added successfully',
          additionalMessage: 'This job is now saved to your Overview Board.',
        },
      );
    } catch (error) {
      addToast(
        {
          type: 'error',
          message: 'Failed to add a job',
          additionalMessage: "We're looking into the issue. Please try again later.",
        },
      );
      trackEvent('Toast Error Shown', user, {
        message: 'Failed to add a job',
        error,
      });
    }
  };

  const closeAddJob = () => {
    changeTab('Jobs');
  };

  const showJobDeleteWarning = (jobID: string, jobTitle: string) => {
    openModal(
      <InfoModal
        title="Are you sure?"
        buttonLabel="Delete"
        secondaryButtonLabel="Cancel"
        handleButtonClick={() => handleJobDelete(jobID)}
        handleSecondaryButtonClick={closeModal}
        description={`You are about to delete the job posting for ${jobTitle} permanently. If you would like for this job to stay on the page for future reference, select the cancel button.`}
      />,
    );
  };

  useEffect(() => {
    revalidate();
  }, []);

  useEffect(() => {
    const newSearchParams = new URLSearchParams(window.location.search);

    if (newSearchParams.has('manuallyAddJob') && newSearchParams.get('manuallyAddJob') === 'true') {
      changeTab('Jobs', 'addJobManually');
    }
  }, []);

  if (tabState === 'addJobManually') {
    return (
      <div className="jobs-tab">
        <div className="jobs-tab__back-button">
          <Button
            mode="invisible"
            size="medium"
            label="Back"
            icon="bi bi-arrow-left"
            handleClick={closeAddJob}
          />
        </div>

        <div className="white-bordered-box">
          <ManuallyAddJob
            handleJobAdd={handleAddJob}
            companyName={companyName}
            companyID={companyId}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="jobs-tab">
      <div className="white-bordered-box">
        <div className="jobs-tab__header">
          <h3 className="jobs-tab__title">
            Saved jobs
          </h3>

          {jobs && jobs.length > 0 && (
            <AddJobButton
              buttonLabel="Add job"
              changeTab={changeTab}
              listPosition="right"
              companyLinkedIn={companyLinkedIn}
              icon="bi bi-plus-circle"
              mode="invisible"
              withSaveManually
            />
          )}
        </div>

        {jobs ? jobs.map((job) => (
          <div key={job.id} className="jobs-tab__job">
            <TrackerJobTile
              revalidate={revalidate}
              title={job.title}
              location={job.location}
              linkedInUrl={job.linkedin_url}
              description={job.description}
              deleteJob={() => showJobDeleteWarning(job.id, job.title)}
              key={job.id}
              id={job.id}
              companyLogo={companyLogo}
              companyName={companyName}
              applicationDeadline={job.deadline ? job.deadline : ''}
              isDateRolling={job.rolling_date}
            />
          </div>
        )) : (
          <div className="jobs-tab__empty-state">
            <p className="jobs-tab__description">
              Looks like you haven’t saved any jobs yet.
              <br />
              Save a job now to kickstart your career journey!
            </p>
            <div className="jobs-tab__actions">
              <Button
                mode="primary"
                size="medium"
                icon="bi bi-plus-circle"
                outlined
                label="Save Job Manually"
                handleClick={openAddJob}
              />
              <span>or</span>
              <AddJobButton
                mode="primary"
                size="medium"
                companyLinkedIn={companyLinkedIn}
                icon="bi bi-box-arrow-up-right"
                buttonLabel="Save Jobs from Job Boards"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
