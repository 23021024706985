import {
  useContext,
  useEffect, useRef, useState,
} from 'react';
import './linkedin-actions.scss';
import ReactQuill from 'react-quill';
import { IconButton } from '@/components/icon-button/icon-button';
import { GenerateMessageOptions } from '@/domains/core/contact';
import { UseAIButton } from '@/domains/core/student';
import { convertNewLinesToHtml, findTextInBrackets } from '@/services/helpers/messages';
import useDebounceEffect from '@/services/hooks/use-debounce';
import { useAnalytics } from '@/services/hooks/use-analytics';
import '@/services/helpers/quill-config';
import { OutreachScenarios } from '../outreach-scenarios/outreach-scenarios';
import { useOutreachScenario } from '@/services/hooks/use-outreach-scenario';
import { ContactPlaceholders, OutreachScenarioType, TemplateRequestPayload } from '../../types';
import { ModalContext } from '@/components/modal/modal-provider';
import { CustomTemplatesModal } from '../custom-templates-modal/custom-templates-modal';
import { useInboxContext } from '@/pages/context/inbox';
import { TemplatesButton } from '../templates-button/templates-button';
import { useSelfUniversity } from '@/services/queries/user';
import { CatolicaTemplate } from '../outreach-scenarios/scenarios';
import { prettifyHTML } from '@/services/helpers/processHtml';

type LinkedinActionsProps = {
  contactName: string;
  contactID: string;
  sendMessage: (message: string) => void;
  disabled?: boolean
  isFirstOutreach?: boolean;
  regenerateData?: TemplateRequestPayload;
};

export const LinkedinActions = ({
  contactName,
  sendMessage,
  contactID,
  disabled = false,
  isFirstOutreach = false,
  regenerateData,
}: LinkedinActionsProps) => {
  const { currentContact } = useInboxContext() || {};
  const { trackEvent } = useAnalytics();
  const { data: univeristy } = useSelfUniversity();
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const [message, setMessage] = useState<any>('');
  const [warningMessage, setWarningMessage] = useState<string>('');
  const reactQuillRef = useRef<ReactQuill | null>(null);
  const {
    showScenarios,
    regenerateScenario,
    isLoadingOutreachScenario,
    hideScenarios,
    scenarioTemplate,
    toggleScenarioVisibility,
    showRegenerateScenario,
    displayScenarios,
    cancelRegenerateScenario,
  } = useOutreachScenario();
  const quillEditor = useRef<any>(null);
  const [contactPlaceholders, setContactPlaceholders] = useState<ContactPlaceholders>();

  const applyTemplate = (template: string) => {
    setMessage(template);
    hideScenarios();
    closeModal();
  };

  const checkForCatolica = (sc: OutreachScenarioType) => {
    if (sc === 'meet_at_in_person_career_fair' && univeristy?.name === 'Católica Lisbon School of Business') {
      setMessage(prettifyHTML(CatolicaTemplate(regenerateData?.studentName || '[Your First Name]')));
      hideScenarios();
      return;
    }
    regenerateScenario(regenerateData, sc);
  };

  const handleCustomTemplatesClick = () => {
    if (!contactPlaceholders) {
      return;
    }
    openModal(
      <CustomTemplatesModal
        contactPlaceholders={contactPlaceholders}
        previewType="linkedin"
        selectTemplate={applyTemplate}
      />,
      {
        closeOnClickOutside: false,
      },
    );
  };

  const handleMessageChange = (value: string) => {
    setMessage(value);
  };

  const handleWriteMessageSelection = () => {
    hideScenarios();
    setMessage('');
  };

  const handleRegenerateClick = (msg: string) => {
    setMessage(convertNewLinesToHtml(msg));
    trackEvent('Inbox Linkedin Message AI Generated');
  };
  const checkTextForPlaceholders = () => {
    const hasPlaceholders = message.match(/\[[^[\]]*\]/g);

    if (hasPlaceholders) {
      setWarningMessage('Please make sure to replace the placeholders in brackets with the according information.');
    } else {
      setWarningMessage('');
    }

    return !hasPlaceholders;
  };

  const handleClickSendMessage = () => {
    const currentValue = quillEditor.current?.getText() || '';
    const trimmedValue = currentValue.trim();

    if (trimmedValue.length === 0) {
      return;
    }

    if (!checkTextForPlaceholders()) return;

    sendMessage(trimmedValue);
    quillEditor.current?.setText('');
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
      event.preventDefault();
      handleClickSendMessage();
    }
  };

  const attachQuillRefs = () => {
    if (!reactQuillRef.current) {
      return;
    }
    if (typeof reactQuillRef.current.getEditor !== 'function') return;
    quillEditor.current = reactQuillRef.current.getEditor();
  };

  const highlightPlaceholders = () => {
    if (!reactQuillRef.current) {
      return;
    }
    const bracketedParts = findTextInBrackets(quillEditor.current.getText());
    quillEditor.current.formatText(0, quillEditor.current.getText().length, {
      color: false,
      bold: false,
    });
    bracketedParts.forEach(part => {
      quillEditor.current.formatText(part.index, part.length, {
        color: 'var(--purpose-information)',
        bold: true,
      });
    });
  };

  useDebounceEffect(() => {
    if (!reactQuillRef.current) {
      return;
    }

    highlightPlaceholders();
  }, [message, contactName, reactQuillRef.current]);

  useEffect(() => {
    attachQuillRefs();
    setMessage('');
  }, [contactName]);

  useEffect(() => {
    toggleScenarioVisibility(isFirstOutreach);
  }, [isFirstOutreach]);

  useEffect(() => {
    if (scenarioTemplate && !isLoadingOutreachScenario) {
      setMessage(scenarioTemplate);
    }
  }, [scenarioTemplate, isLoadingOutreachScenario]);

  useEffect(() => {
    if (currentContact) {
      setContactPlaceholders({
        FirstName: currentContact.first_name,
        LastName: currentContact.last_name,
        Company: currentContact.current_position?.company,
      });
    }
  }, [currentContact?.id]);

  return (
    <div className="linkedin-actions" data-testid="linkedin-actions">
      <div className="linkedin-actions__box">
        {(showScenarios || isLoadingOutreachScenario) && isFirstOutreach && (
          <div className="linkedin-actions__scenarios" data-testid="linkedin-actions-scenarios">
            <OutreachScenarios
              handleApplyTemplate={(sc) => checkForCatolica(sc)}
              handleWriteMessageSelection={handleWriteMessageSelection}
              isLoading={isLoadingOutreachScenario}
            />
          </div>
        )}

        <ReactQuill
          ref={reactQuillRef}
          placeholder="Write a message"
          modules={{
            toolbar: null,
          }}
          theme="snow"
          value={message}
          onChange={handleMessageChange}
          onKeyDown={handleKeyPress}
          style={{
            border: 'none',
            padding: '0',
            borderRadius: '8px',
            display: (showScenarios || isLoadingOutreachScenario) ? 'none' : 'block',
          }}
        />

        {warningMessage && (
          <span data-testid="linkedin-actions-warning" className="linkedin-actions__box-warning">{warningMessage}</span>
        )}
      </div>
      <div className="linkedin-actions__footer">
        <TemplatesButton
          showOutreachButton={isFirstOutreach && !showScenarios && !isLoadingOutreachScenario}
          triggerOutreachTemplateButton={displayScenarios}
          triggerCustomTemplateButton={handleCustomTemplatesClick}
        />
        <div className="linkedin-actions__cta">
          {showRegenerateScenario && (
            <IconButton
              label="Regenerate First Outreach"
              icon={isLoadingOutreachScenario ? 'bi bi-x-lg' : 'bi bi-arrow-clockwise'}
              onClick={() => (isLoadingOutreachScenario ? cancelRegenerateScenario() : regenerateScenario(regenerateData))}
              mode="special"
              size="large"
              outlined
            />
          )}
          {!isFirstOutreach && (
            <UseAIButton
              options={GenerateMessageOptions}
              type="linkedin"
              size="small"
              handleResult={handleRegenerateClick}
              metaData={{
                contactID,
              }}
            />
          )}
          <div className="linkedin-actions__send-button" data-testid="linkedin-actions-send-button">
            <IconButton
              disabled={disabled}
              mode="primary"
              size="large"
              icon="bi bi-send"
              handleClick={handleClickSendMessage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
