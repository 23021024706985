import { ChipTypes } from '@/components/chip/chip';
import { FilterOption } from '../../company/types/company';

export type TagType = {
  tag_type: 'industry'
  | 'contract'
  | 'salary'
  | 'employees'
  | 'location'
  | 'workMode';
  tag_content: string;
};

export type JobFeedJob = {
  tags: TagType[];
  saved: boolean;
  linkedin_logo_url: string;
  has_badge: boolean;
  id: string;
  description: string;
  title: string;
  company_name: string;
  company_id?: string;
  location: string;
  linkedin_url: string;
  note?: string;
  deadline?: string;
  rolling_date?: string;
  posted_on: string;
  source: string;
};

export const JOB_SOURCE = {
  EMPLOYER_APP: 'EmployerApp',
} as const;

export type JobsData = JobFeedJob[];

export type JobsLocationsType = {
  city?: string;
  state?: string;
  country: string;
};

export type JobFilterTagTypes = 'query'
| 'sizes'
| 'job_types'
| 'work_modes'
| 'locations'
| 'industries'
| 'date_range'
| 'languages';

export type JobFilterChipType = { type: ChipTypes, name: string, filterName: JobFilterTagTypes };

export type FilterJobsRequestBody = {
  sizes?: string;
  industries?: string;
  job_types?: string;
  work_modes?: string;
  locations?: string;
  date_range?: string;
  query?: string;
  languages?: string;
};

export type JobBannerType = { type: 'banner' };
export type JobOrBanner = JobFeedJob | JobBannerType;

export type JobsFilterState = {
  query: string;
  sizes: FilterOption[];
  job_types: FilterOption[];
  work_modes: FilterOption[];
  industries: FilterOption[];
  locations: FilterOption[];
  date_range: FilterOption[];
  languages: FilterOption[];
};
