import { useContext, useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { Loader } from '@/components/loader/loader';
import { KanbanBoard } from '@/domains/generic/kanban/kanban-board';
import {
  Tile, StudentOverviewResponse, FilterNameType, FilterOption,
} from '@/domains/core/company/types';
import './styles/overview.scss';
import { AllCommentsSidebar } from '@/domains/generic';
import { SidebarContext } from '@/components/sidebar/sidebar-provider';
import { DocumentTable } from '@/domains/core/student';
import { filterTiles, getAllSavedIndustries, sortByPosition } from '@/services/helpers/company';
import { router } from '@/main';
import { Button } from '@/components/button/Button';
import CustomWithAuthenticationRequired from './auth/custom-protected-route';

const companies: string[] = [
  'Company Saved',
  'Networking',
  'Applying',
  'Applied',
  'Interviewing',
  'Offer',
];

function StudentOverviewPage() {
  const { hash } = window.location;
  const {
    tiles, student, documents, comments,
  } = useLoaderData() as StudentOverviewResponse;
  const [isLoading, setIsLoading] = useState(false);
  const [kanbanLayout, setKanbanLayout] = useState({});
  const { openSidebar, closeSidebar } = useContext(SidebarContext) as any;
  const [filteredTiles, setFilteredTiles] = useState<Tile[]>(tiles);
  const [showDocuments, setShowDocuments] = useState(false);

  const computeKanbanLayout = (allCompanies: Tile[]) => {
    const kanban = {
      'Company Saved': sortByPosition(allCompanies.filter((company: Tile) => company.status === 'saved')),
      Networking: sortByPosition(allCompanies.filter((company: Tile) => company.status === 'networking')),
      Applying: sortByPosition(allCompanies.filter((company: Tile) => company.status === 'applying')),
      Applied: sortByPosition(allCompanies.filter((company: Tile) => company.status === 'applied')),
      Interviewing: sortByPosition(allCompanies.filter((company: Tile) => company.status === 'interviewing')),
      Offer: sortByPosition(allCompanies.filter((company: Tile) => company.status === 'offer')),
    };
    setKanbanLayout(kanban);
  };

  const handleFiltering = (filters: { [name in FilterNameType]: FilterOption[] }, searchQuery: string) => {
    const filteredResult = filterTiles(tiles, filters, searchQuery);

    setFilteredTiles(filteredResult);
  };

  const backButtonClicked = () => {
    // eslint-disable-next-line no-restricted-globals
    if (history) {
      // eslint-disable-next-line no-restricted-globals
      history.back();
    } else {
      router.navigate('/app/dashboard-advisor');
    }
  };

  // Handle Data
  const cardChangeHandler = async (cardInfo: any, newStatus: string) => {
    console.log(cardInfo);
    console.log(newStatus);
  };

  const sortByCreatedAt = (arr: any[]) => arr.sort((a, b) => {
    const dateA: any = new Date(a.created_at);
    const dateB: any = new Date(b.created_at);
    return dateB - dateA;
  });

  const openFullCommentHistory = () => {
    const sortedComments = sortByCreatedAt(comments);
    if (comments.length > 0) {
      openSidebar(<AllCommentsSidebar comments={sortedComments} closeSidebar={closeSidebar} />);
    } else {
      openSidebar(<AllCommentsSidebar comments={sortedComments} closeSidebar={closeSidebar} />);
    }
  };

  const openDocumentsTab = () => setShowDocuments(true);
  const closeDocumentsTab = () => setShowDocuments(false);

  useEffect(() => {
    computeKanbanLayout(filteredTiles);
    // trackEvent('Opened a Student’s Overview Board');
  }, [filteredTiles.length]);

  useEffect(() => {
    if (hash === '#Documents') {
      setShowDocuments(true);
    }
  }, []);

  useEffect(() => {
    console.log('student', student);
    if (!student) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [student]);

  if (isLoading) {
    return (
      <div id="loader-zone">
        <Loader />
      </div>
    );
  }
  return (
    <div className="advisor-overview">
      <div className="advisor-overview__top">

        <Button
          mode="invisible"
          size="medium"
          icon="bi bi-arrow-left"
          label="Back"
          handleClick={backButtonClicked}
        />
        <Button
          mode="primary"
          size="medium"
          icon="bi bi-kanban"
          label={`${student?.first_name} ${student?.last_name}`}
          outlined={showDocuments}
          handleClick={closeDocumentsTab}
        />
        <Button
          mode="primary"
          size="medium"
          icon="bi bi-file-earmark"
          label="Documents"
          outlined={!showDocuments}
          handleClick={openDocumentsTab}
        />
        {!showDocuments && (
          <div onClick={openFullCommentHistory} className="advisor-overview__top__comments-history">
            Review full comment history
            <i className="bi bi-box-arrow-up-right" />
          </div>
        )}
      </div>
      {showDocuments ? (
        <div className="white-wrapper">
          <h2 className="advisor-overview__title">Documents</h2>
          <div className="advisor-overview__subtitle">
            <p>Relevant documents for this student’s job search.</p>
          </div>
          <DocumentTable accessType="advisor" documents={documents} />
        </div>
      ) : (
        <KanbanBoard
          savedIndustries={getAllSavedIndustries(tiles)}
          handleFiltersChange={handleFiltering}
          readOnly
          onChange={cardChangeHandler}
          columns={companies}
          layout={kanbanLayout}
        />
      )}
    </div>
  );
}

export default CustomWithAuthenticationRequired(StudentOverviewPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
