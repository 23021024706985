/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import {
  Page, Text, View, Document, Link, StyleSheet,
  Font,
} from '@react-pdf/renderer';
import { HighlightWords, ResumeSchema } from '../../types';

import Calibri from '@/assets/fonts/resume-builder/calibri-font-family/calibri-regular.ttf';
import CalibriBold from '@/assets/fonts/resume-builder/calibri-font-family/calibri-bold.ttf';
import CalibriItalic from '@/assets/fonts/resume-builder/calibri-font-family/calibri-italic.ttf';

import { computeSkillsStyle, renderAdditionalHighlightedText, renderWorkHighlightedText } from './utils';
import { formatDate } from '../../helpers/formate-date-templates';

Font.register({ family: 'Calibri', src: Calibri, fonts: [{ src: Calibri, fontWeight: '400' }, { src: CalibriBold, fontWeight: '700' }, { src: CalibriItalic, fontStyle: 'italic' }] });

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: '#fff',
    fontFamily: 'Calibri',
    width: '100%',
    height: '100%',
    padding: '26px',
    margin: 0,
  },
  document: {
    width: '100%',
    height: '100%',
    margin: 0,
    padding: 0,
    border: 0,
    background: 'white',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
  },
  headerLeftSide: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    textAlign: 'left',
    width: '40%',
  },
  headerLeft: {
    fontSize: 11,
    fontFamily: 'Calibri',
    textAlign: 'left',
    color: '#000000',
  },
  headerLeftBlack: {
    fontSize: 11,
    fontFamily: 'Calibri',
    textAlign: 'left',
    color: '#000',
  },
  headerRightBlack: {
    fontSize: 11,
    fontFamily: 'Calibri',
    textAlign: 'left',
    color: '#000',
    width: '100%',
  },
  headerRight: {
    fontSize: 11,
    fontFamily: 'Calibri',
    textAlign: 'left',
    width: '100%',
  },
  headerRightSide: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    textAlign: 'left',
    width: '40%',
  },
  topRow: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
  },
  bottomRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 5,
    width: '100%',
  },
  block: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    marginTop: 8,
  },
  EducationblockTitle: {
    fontSize: 11,
    fontFamily: 'Calibri',
    textAlign: 'left',
    width: '100%',
    color: '#000',
    borderBottom: '1px solid #1F497D',
  },
  WorkblockTitle: {
    fontSize: 11,
    fontFamily: 'Calibri',
    textAlign: 'left',
    width: '100%',
    color: '#000',
    borderBottom: '1px solid #1F497D',
  },
  LanguagesBlockTitle: {
    fontSize: 11,
    fontFamily: 'Calibri',
    textAlign: 'left',
    width: '100%',
    color: '#000',
    borderBottom: '1px solid #1F497D',
  },
  AdditionalBlockTitle: {
    fontSize: 11,
    fontFamily: 'Calibri',
    textAlign: 'left',
    width: '100%',
    color: '#000',
    borderBottom: '1px solid #1F497D',
  },
  educationBlock: {
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: 10,
    paddingLeft: '2px',
  },
  WorkBlock: {
    flexDirection: 'column',
    width: '100%',
    paddingLeft: '2px',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: 10,
  },
  row: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    rowGap: 5,
  },
  EdRow: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  SummaryRow: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    paddingLeft: '2px',
  },
  rowDescription: {
    flexDirection: 'row',
    width: '100%',
    height: 'auto',
  },
  Edinstitution: {
    fontSize: 9,
    fontFamily: 'Calibri',
    fontWeight: 'bold',
    marginBottom: 5,
    textAlign: 'left',
    color: '#000000',
  },
  EdLocation: {
    fontSize: 9,
    fontFamily: 'Calibri',
    fontWeight: 'bold',
    textAlign: 'right',
    color: '#000',
  },
  EdstudyType: {
    fontSize: 11,
    fontFamily: 'Calibri',
    fontStyle: 'italic',
    textAlign: 'left',
    color: '#000',
  },
  EdDates: {
    fontSize: 11,
    fontFamily: 'Calibri',
    textAlign: 'right',
    color: '#000000',
  },
  EdDescription: {
    fontSize: 11,
    width: '100%',
    height: 'auto',
    color: '#000000',
  },
  workInstitution: {
    fontSize: 9,
    fontFamily: 'Calibri',
    fontWeight: 'bold',
    color: '#000',
  },
  workLocation: {
    fontSize: 9,
    textAlign: 'right',
    fontWeight: 'bold',
    fontFamily: 'Calibri',
    color: '#000',
  },
  workPosition: {
    fontSize: 11,
    fontFamily: 'Calibri',
    fontStyle: 'italic',
    fontWeight: 'bold',
    color: '#000000',
  },
  workDescription: {
    fontSize: 11,
    fontFamily: 'Calibri',
    fontStyle: 'italic',
    color: '#000000',
  },
  workDates: {
    fontSize: 11,
    color: '#000000',
  },
  highlights: {
    marginTop: 2,
    width: '100%',
  },
  listItem: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
  },
  bulletPoint: {
    width: 10,
    fontSize: 11,
  },
  highlightText: {
    fontSize: 11,
    color: '#000000',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    height: 'auto',
  },
  nameRow: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    marginTop: 0,
  },
  headerTopSide: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    columnGap: 10,
    marginBottom: '6px',
    marginTop: 0,
  },
  headerTopSideText: {
    fontSize: 11,
    fontFamily: 'Calibri',
    color: '#000',
    textAlign: 'center',
  },
  headerJobTitle: {
    fontSize: 14,
    fontFamily: 'Calibri',
    fontWeight: 'bold',
    color: '#000',
    textAlign: 'center',
  },
  headerTopSideTextTwo: {
    fontSize: 11,
    fontFamily: 'Calibri',
    fontWeight: 'bold',
    color: '#000',
    textAlign: 'center',
  },
  nameText: {
    fontSize: 16,
    fontFamily: 'Calibri',
    fontWeight: 'bold',
    color: '#000',
    textAlign: 'center',
    marginBottom: '6px',
  },

  link: {
    color: '#0112FC',
  },
  blockWithBorder: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 5,
  },
  blockBorder: {
    borderBottomWidth: 2,
    borderBottomColor: '#1F497D',
    borderBottomStyle: 'solid',
    width: '100%',
  },
  blockTitleText: {
    fontSize: 9,
    paddingLeft: '2px',
    fontFamily: 'Calibri',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  languages: {
    flexDirection: 'row',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  languagesText: {
    fontSize: 11,
    fontFamily: 'Calibri',
    color: '#000000',
  },
  boldText: {
    fontSize: 11,
    fontFamily: 'Calibri',
    fontWeight: 'bold',
    color: '#000000',
  },
  languagesTextBold: {
    fontSize: 11,
    fontFamily: 'Calibri',
    fontWeight: 'bold',
    color: '#000000',
  },
  normalWord: {
    color: 'black',
  },
  GreenHighlight: {
    color: '#07795C',
    textDecoration: 'underline',
    backgroundColor: '#bbece880',
  },
  RedHighlight: {
    color: '#BA1A1A',
    textDecoration: 'underline',
    backgroundColor: '#ffdad680',
  },
  SkillsGreenHighlight: {
    color: '#07795C',
    textDecoration: 'underline',
    backgroundColor: '#bbece880',
    fontSize: 11,
    fontFamily: 'Calibri',
  },
  SkillsRedHighlight: {
    color: '#BA1A1A',
    textDecoration: 'underline',
    backgroundColor: '#ffdad680',
    fontSize: 11,
    fontFamily: 'Calibri',
  },
  SkillsSubtleGreenHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#bbece880',
    fontSize: 11,
    fontFamily: 'Calibri',
  },
  SubtleGreenHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#bbece880',
    fontSize: 11,
    fontFamily: 'Calibri',
  },
  SubtleRedHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#ffdad680',
    fontSize: 11,
    fontFamily: 'Calibri',
  },
  SkillsSubtleRedHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#ffdad680',
    fontSize: 11,
    fontFamily: 'Calibri',
  },
  skillsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    columnGap: 10,
  },
  skillColumn: {
    flex: 1,
    marginRight: 10, // Add some space between columns
  },
  skillItem: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 2, // Space between skills
  },
  skillBullet: {
    marginRight: 5,
    fontSize: 11,
  },
  skillText: {
    fontSize: 11,
    fontFamily: 'Calibri',
  },
  container: {
    flexDirection: 'row',
    borderBottom: 1,
    borderColor: '#1F497D',
    width: '100%',
  },
  firstLetter: {
    fontSize: 12,
  },
  restOfWord: {
    fontSize: 8,
  },
  firstLetterName: {
    fontSize: 21,
  },

});

type ResumeSchemaProps = {
  resume: ResumeSchema,
  title: string,
  highlightedWords: HighlightWords | null,
  activeField: { type: 'work' | 'additional_information'; index: number; bulletIndex: number } | null
};

const CustomSectionTitle: React.FC<{ title: string }> = ({ title }) => {
  const words = title?.split(' ');

  return (
    <View style={styles.container}>
      {words.map((word, index) => (
        <Text key={index} style={styles.blockTitleText}>
          <Text style={styles.firstLetter}>{word.charAt(0).toUpperCase()}</Text>
          <Text style={styles.restOfWord}>{word.slice(1).toUpperCase()}</Text>
          {index < words.length - 1 && ' '}
        </Text>
      ))}
    </View>
  );
};

const CustomNameTitle: React.FC<{ title: string }> = ({ title }) => {
  if (!title) {
    return null;
  }
  const words = title.split(' ');
  return (
    <View style={styles.nameRow}>
      {words.map((word, index) => (
        <Text key={index} style={styles.nameText}>
          <Text style={styles.firstLetterName}>{word.charAt(0).toUpperCase()}</Text>
          <Text>{word?.slice(1)?.toUpperCase()}</Text>
          {index < words.length - 1 && ' '}
        </Text>
      ))}
    </View>
  );
};

// Create Document Component
const AMSTemplate = ({
  resume, title, highlightedWords, activeField,
}: ResumeSchemaProps) => (
  <Document title={title} style={styles.document}>
    <Page size="A4" style={styles.page}>
      <CustomNameTitle title={resume.basics.name || ''} />
      <View style={styles.header}>
        <View style={styles.headerTopSide}>
          {resume.basics?.emails?.map((email) => (
            <Text key={email} style={styles.headerTopSideText}>
              <Link style={styles.link} key={email} href={`mailto:${email}`}>
                {email}
              </Link>
            </Text>
          ))}
          {resume.basics.phone_numbers?.map((phone) => (
            <Text key={phone} style={styles.headerTopSideText}>
              {phone}
            </Text>
          ))}
          <Text style={styles.headerTopSideText}>
            <Link style={styles.link} key={resume.basics.url} href={`${resume.basics.url}`}>
              {resume.basics.url}
            </Link>
          </Text>
        </View>
      </View>
      <View style={styles.block}>
        <View style={styles.blockWithBorder}>
          <CustomSectionTitle title="PROFESSIONAL SUMMARY" />
        </View>
        {resume.basics.summary && (
        <View style={styles.SummaryRow}>
          <Text style={styles.EdDescription}>
            {resume.basics.summary}
          </Text>
        </View>
        ) }
      </View>
      <View style={styles.block}>
        <View style={styles.blockWithBorder}>
          <CustomSectionTitle title="EDUCATION" />
        </View>
        {resume.education?.map((item) => (
          <View key={item.institution} style={styles.educationBlock}>
            <View style={styles.row}>
              <Text style={styles.Edinstitution}>
                {item.institution?.toLocaleUpperCase()}
              </Text>
              <Text style={styles.EdLocation}>
                {item.location?.toLocaleUpperCase()}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.EdstudyType}>
                {item.area}
              </Text>
              <Text style={styles.EdDates}>
                {formatDate(item.startDate)}
                {' '}
                -
                {item.endDate === 'Present' ? item.endDate : formatDate(item.endDate)}
              </Text>
            </View>
            {item.description && item.description.map((desc) => (
              <View key={desc} style={styles.EdRow}>
                <Text style={styles.bulletPoint}>•</Text>
                <Text style={styles.EdDescription}>
                  {desc}
                </Text>
              </View>
            ))}
          </View>
        ))}
      </View>
      <View style={styles.block}>
        <View style={styles.blockWithBorder}>
          <CustomSectionTitle title="PROFESSIONAL EXPERIENCE" />
        </View>
        {resume.work?.map((item, index) => (
          <View key={`${item.name}-${index}`} style={styles.WorkBlock}>
            <View style={styles.row}>
              <Text style={styles.workInstitution}>
                {item.name?.toLocaleUpperCase()}
              </Text>
              <Text style={styles.workLocation}>
                {item.location?.toLocaleUpperCase()}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.workPosition}>
                {item.position}
              </Text>
              <Text style={styles.workDates}>
                {formatDate(item.startDate)}
                {' '}
                -
                {item.endDate === 'Present' ? item.endDate : formatDate(item.endDate)}
              </Text>
            </View>
            {item.highlights && item.highlights.length > 0 && (
              <View style={styles.highlights}>
                {item.highlights.map((highlight, highlightIndex) => {
                  const cleanedHighlight = highlight.replace(/^•\s/, '');

                  return (
                    <View key={`${highlight}-${highlightIndex}`} style={styles.listItem} wrap={false}>
                      <Text style={styles.bulletPoint}>•</Text>
                      <View style={styles.highlightText}>
                        {renderWorkHighlightedText(cleanedHighlight, highlightIndex, index, highlightedWords, activeField, styles)}
                      </View>
                    </View>
                  );
                })}
              </View>
            )}
          </View>
        ))}
      </View>
      <View style={styles.block}>
        <View style={styles.blockWithBorder}>
          <CustomSectionTitle title="OTHER EXPERIENCES & INTERESTS" />
        </View>
        {resume.additional_information?.bullets?.length > 0
          && (
            <View style={styles.WorkBlock}>
              <View style={styles.highlights}>
                {resume.additional_information.bullets?.map((bullet, bulletIndex) => bullet.replace(/^•\s/, '') && (
                  <View key={`${bullet}-${bulletIndex}`} style={styles.listItem} wrap={false}>
                    <Text style={styles.bulletPoint}>•</Text>
                    <View style={styles.highlightText}>
                      {renderAdditionalHighlightedText(bullet.replace(/^•\s/, ''), bulletIndex, highlightedWords, activeField, styles)}
                    </View>
                  </View>
                ))}
              </View>

            </View>
          )}
      </View>
      <View style={styles.block}>
        <View style={styles.blockWithBorder}>
          <CustomSectionTitle title="LANGUAGES" />
        </View>
        <View style={styles.WorkBlock}>
          <View style={styles.languages}>
            {resume.languages?.map((language, index) => (
              <Text key={index} style={styles.languagesText}>
                <Text style={styles.boldText}>{language.language}</Text>
                {` (${language.fluency})`}
                {index < resume.languages.length - 1 ? ', ' : ''}
              </Text>
            ))}
          </View>
        </View>
      </View>
      <View style={styles.block}>
        <View style={styles.blockWithBorder}>
          <CustomSectionTitle title="SKILLS" />
        </View>
        <View style={styles.WorkBlock}>
          <View style={styles.skillsContainer}>
            {resume.skills?.reduce((columns, skill, index) => {
              const columnIndex = index % 3;
              if (!columns[columnIndex]) {
                columns[columnIndex] = [];
              }
              columns[columnIndex].push(
                <View key={skill.name} style={styles.skillItem}>
                  <Text style={styles.skillBullet}>•</Text>
                  <Text style={[
                    styles.skillText,
                    computeSkillsStyle(highlightedWords, activeField, styles),
                  ]}
                  >
                    <Text style={styles.boldText}>{skill.name}</Text>
                    {skill.level && ` (${skill.level})`}
                  </Text>
                </View>,
              );
              return columns;
            }, []).map((column: any, index: number) => (
              <View key={index} style={styles.skillColumn}>
                {column}
              </View>
            ))}
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

export default AMSTemplate;
