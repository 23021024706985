import {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLoaderData } from 'react-router-dom';
import {
  contractStaticOptions,
  languageMap,
  postedDateStaticOptions,
  workTypeStaticOptions,
} from './constants';
import { industries, mapAndSortCompanySizes } from '@/domains/core/company/components/companies-filters/data-parser';
import { FilteredCompaniesResponse, FilterOption } from '@/domains/core/company/types';
import { Chip, ChipTypes } from '@/components/chip/chip';
import {
  JobFilterChipType,
  JobFilterTagTypes,
  JobsFilterState,
  JobsLocationsType,
} from '../../types/job';
import { Button } from '@/components/button/Button';
import { DropdownSearch } from '@/components/dropdown-search/dropdown-search';
import { MultiSelect } from '@/components/multi-select/multi-select';
import { FilterSearchBar } from '@/components/filter-search-bar/filter-search-bar';
import { convertURLParamsToFilters } from '../../helpers/convertURLParamsToFilters';
import { IconButton } from '@/components/icon-button/icon-button';
import { MobileJobFilters } from '@/domains/core/job';
import { ModalContext } from '@/components/modal/modal-provider';
import { InfoModal } from '@/domains/generic/modals';
import { JobsFiltersModal } from './job-filters-modal';
import { useAnalytics } from '@/services/hooks/use-analytics';
import './job-filters.scss';
import { isMobileDevice } from '@/services/helpers/responsive';

type Props = {
  filters: JobsFilterState,
  jobsLocations: JobsLocationsType[],
  jobsLanguages: string[],
  setFilters: (filters: any) => void,
};

export const JobFilters = ({
  filters,
  jobsLocations,
  jobsLanguages,
  setFilters,
}: Props) => {
  const [selectedFiltersTags, setSelectedFiltersTags] = useState<JobFilterChipType[]>([]);
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);
  const {
    sizes,
  } = useLoaderData() as FilteredCompaniesResponse;
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const { trackEvent } = useAnalytics();

  const jobsLocationsOptions = useMemo(() => {
    if (!Array.isArray(jobsLocations)) {
      return [];
    }

    return jobsLocations
      .filter(location => {
        const locationName = [location.city, location.state, location.country]
          .filter(Boolean)
          .join(', ');
        return filters?.locations?.every(selectedLocation => selectedLocation.name !== locationName);
      })
      .map(location => ({
        name: [location.city, location.state, location.country]
          .filter(Boolean)
          .join(', '),
        value: JSON.stringify(location),
      }));
  }, [jobsLocations, filters?.locations]);

  const jobsLanguagesOptions: FilterOption[] = jobsLanguages?.map(language => ({
    name: languageMap[language] || language,
    value: language,
  }));
  const industriesOptions: FilterOption[] = useMemo(() => industries.map((industry) => ({ name: industry, value: industry })), []);
  const sizesOptions: FilterOption[] = useMemo(() => mapAndSortCompanySizes(sizes), []);
  const locationOptions = useMemo(() => filters.locations?.reduce<string[]>((acc, location) => {
    if (typeof location.name === 'string') {
      acc.push(location.name);
    }
    return acc;
  }, []) || [], [filters.locations]);

  const staticOptions = {
    locations: jobsLocationsOptions,
    contract: contractStaticOptions,
    postedDate: postedDateStaticOptions,
    workType: workTypeStaticOptions,
    industries: industriesOptions,
    sizes: sizesOptions,
    languages: jobsLanguagesOptions,
  };

  const updateSearchParamsWithNewFilters = (
    name: string,
    value: FilterOption[] | boolean | string,
  ) => {
    const newSearchParams = new URLSearchParams(window.location.search);
    newSearchParams.delete(name);

    if (typeof value === 'boolean' || typeof value === 'string') {
      newSearchParams.append(name, value.toString());
    } else if (name === 'locations') {
      newSearchParams.append(name, JSON.stringify(value.flatMap((v) => v.value)));
    } else if (value.length > 0) {
      newSearchParams.append(
        name,
        value.map((option) => option.value).join(','),
      );
    }

    window.history.replaceState(null, '', `?${newSearchParams.toString()}`);
  };

  const updateFilterTags = (newFilters: typeof filters) => {
    const newTags = Object.entries(newFilters).flatMap(([filterName, filterOptions]) => {
      if (typeof filterOptions === 'object' && 'name' in filterOptions) {
        return [{
          type: filterName as ChipTypes,
          name: filterOptions.name,
          filterName: filterName as JobFilterTagTypes,
        }];
      }
      return Array.isArray(filterOptions) ? filterOptions.map((option) => ({
        type: filterName as ChipTypes,
        name: typeof option.name === 'string' ? option.name : '',
        filterName: filterName as JobFilterTagTypes,
      })) : [];
    }).filter(tag => tag.name !== '');

    trackEvent('Filters applied', { filter_type: newTags.map((tag) => tag.filterName) });

    setSelectedFiltersTags(newTags as { type: 'size' | 'tag' | 'location' | 'industry'; name: string; filterName: JobFilterTagTypes; }[]);
  };

  const saveMobileFilters = (newFilters: typeof filters) => {
    setFilters(newFilters);
    (Object.keys(newFilters) as Array<keyof typeof newFilters>).forEach((filterName) => {
      const filterValue = newFilters[filterName];
      if (typeof filterValue === 'string' || typeof filterValue === 'boolean' || Array.isArray(filterValue)) {
        updateSearchParamsWithNewFilters(filterName, filterValue);
      }
    });
    updateFilterTags(newFilters);
  };

  const handleFilterChange = (name: string, options: FilterOption[]) => {
    const newFilters = { ...filters, [name]: options };

    setFilters(newFilters);
    updateSearchParamsWithNewFilters(name, options);
    updateFilterTags(newFilters);
  };

  const handleFilterDelete = (filterName: JobFilterTagTypes, name: string) => {
    const newFilters = {
      ...filters,
      [filterName]: Array.isArray(filters[filterName])
        ? (filters[filterName] as FilterOption[]).filter((option: FilterOption) => option.name !== name)
        : [],
    };
    setFilters(newFilters);
    updateSearchParamsWithNewFilters(filterName, newFilters[filterName] as FilterOption[]);
    updateFilterTags(newFilters);
  };

  const handleFilterReset = () => {
    const resetFilters = {
      query: '',
      sizes: [],
      job_types: [],
      work_modes: [],
      industries: [],
      locations: [],
      date_range: [],
      languages: [],
    };
    setFilters(resetFilters);
    setSelectedFiltersTags([]);
    window.history.replaceState(null, '', '?');
  };

  const handleFiltersInSearchParams = () => {
    const { search } = window.location;
    const searchParams = new URLSearchParams(search);
    const filtersFromSearch = convertURLParamsToFilters(filters, searchParams, { industriesOptions, sizesOptions, jobsLanguagesOptions });

    filtersFromSearch.query = searchParams.get('query') || '';

    setFilters(filtersFromSearch);
    updateFilterTags(filtersFromSearch);
  };

  const handleQueryChange = (item: string) => {
    setFilters((prevFilters: typeof filters) => ({
      ...prevFilters,
      query: item,
    }));

    updateSearchParamsWithNewFilters('query', item);
  };

  const handleLocationSelect = (name: string, value: string) => {
    let parsedValue: JobsLocationsType;
    try {
      parsedValue = JSON.parse(value);
    } catch (error) {
      return;
    }

    const locationName = [parsedValue.city, parsedValue.state, parsedValue.country]
      .filter(Boolean)
      .join(', ');

    const newLocations = [...filters.locations, { name: locationName, value: JSON.stringify(parsedValue) }];
    const newFilters = { ...filters, locations: newLocations };

    setFilters(newFilters);
    updateSearchParamsWithNewFilters('locations', newLocations);
    updateFilterTags(newFilters);
    trackEvent('Job location search', parsedValue);
  };

  const handleFiltersMobileButtonClick = () => {
    setIsMobileModalOpen(!isMobileModalOpen);
    trackEvent('More filters clicked');
  };

  const handleCancelButtonClick = () => {
    handleFilterReset();
    closeModal();
  };

  const openMoreFiltersModal = () => {
    openModal(
      <InfoModal
        title="Additional Filters"
        description={(
          <JobsFiltersModal
            filters={filters}
            staticOptions={staticOptions}
            handleFilterChange={handleFilterChange}
          />
        )}
        buttonLabel="Apply Filters"
        handleButtonClick={() => closeModal()}
        secondaryButtonLabel="Cancel"
        handleSecondaryButtonClick={() => handleCancelButtonClick()}
      />,
    );
    trackEvent('More filters clicked');
  };

  useEffect(() => {
    if (new URLSearchParams(window.location.search).toString()) {
      handleFiltersInSearchParams();
    }
  }, [window.location.search, jobsLanguages]);

  return (
    <div className="job-filters">
      <div className="job-filters__container">
        <div className="job-filters__filters">
          <FilterSearchBar
            value={filters.query}
            placeholder="Search job title"
            onChange={handleQueryChange}
          />

          <DropdownSearch
            selectedOption={locationOptions}
            options={jobsLocationsOptions}
            handleItemSelect={(item, value) => handleLocationSelect(item, value)}
            placeholder="Search job location"
            name="loaction-search"
            id="location-search"
            inputIcon="bi bi-geo-alt"
            size="medium"
            canAddItems={false}
            showIcon={false}
            withMultipleSelection
            openOnClick
            listWidth="full"
          />

          <div className="job-filters__filters-mobile-container">
            <MultiSelect
              labelType="list"
              handleFiltering={(options: FilterOption[]) => handleFilterChange('job_types', options)}
              placeholder="Full-time"
              selected={filters.job_types}
              options={contractStaticOptions}
              width="fit-box"
              size={isMobileDevice ? 'full' : 'medium'}
            />

            <div className="job-filters__all-filters-mobile-button">
              <IconButton mode={isMobileModalOpen ? 'primary' : 'unset'} icon="bi bi-filter-circle" size="small" handleClick={handleFiltersMobileButtonClick} />
            </div>
          </div>

          <MultiSelect
            labelType="list"
            handleFiltering={(options: FilterOption[]) => handleFilterChange('languages', options)}
            placeholder="Job Ad Language"
            selected={filters.languages}
            options={jobsLanguagesOptions}
            width="fit-box"
          />
        </div>

        <div className="job-filters__all-filters-button">
          <Button mode="primary" outlined size="medium" label="More Filters" icon="bi bi-funnel" handleClick={openMoreFiltersModal} />
        </div>
      </div>

      {selectedFiltersTags.length > 0 && (
        <div className="job-filters__filter-labels" data-testid="job-filters-chips">
          {selectedFiltersTags.map((tag) => (
            <Chip
              key={`${tag.filterName}-${tag.name}`}
              label={tag.name}
              type={tag.type}
              handleClose={() => handleFilterDelete(tag.filterName, tag.name)}
            />
          ))}
          <Button
            mode="reset"
            outlined
            size="medium"
            label="Reset"
            handleClick={handleFilterReset}
            icon="bi bi-arrow-repeat"
            data-testid="job-filters-reset-button"
          />
        </div>
      )}

      {isMobileModalOpen && (
        <MobileJobFilters
          filters={filters}
          jobsLocations={jobsLocations}
          staticOptions={staticOptions}
          setFilters={saveMobileFilters}
          handleClose={() => setIsMobileModalOpen(false)}
        />
      )}
    </div>
  );
};
