import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import * as Sentry from '@sentry/react';
import { Loader } from '@/components/loader/loader';
import CustomWithAuthenticationRequired from '../auth/custom-protected-route';
import { Button } from '@/components/button/Button';
import { router } from '@/main';
import { MultiSelect } from '@/components/multi-select/multi-select';
import { useSelfUniversity } from '@/services/queries/user';
import { useGetCohortsByUniversityID } from '@/services/queries/student';
import { FilterOption } from '@/domains/core/company/types';
import { Select } from '@/components/select/select';
import { useGetResumeTemplates } from '@/services/queries/resume';
import { ResumeBookParams, ResumeTemplates } from '@/domains/core/resume/types';
import { Input } from '@/components/input/input';
import { Checkbox } from '@/components/checkbox/checkbox';
import { ModalContext } from '@/components/modal/modal-provider';
import { InfoModal } from '@/domains/generic/modals';
import { LoadingModal } from '@/domains/generic/modals/loading/loading-modal';
import { getResumeBook, getResumeBookCount } from '@/services/api/resume';
import { getDefaultTemplateByUniversity, mapCohortsToFilterOptions } from '@/domains/core/resume/helpers/resume-book';
import { Tooltip } from '@/components/tooltip/tooltip';
import '../styles/resume-book.scss';

function ResumeBookPage() {
  const [selectedCohorts, setSelectedCohorts] = useState<FilterOption[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');
  const [minScore, setMinScore] = useState<string>('');
  const [resumeSelectionPreference, setResumeSelectionPreference] = useState<string>('');
  const [randomizeOrder, setRandomizeOrder] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [resumeCount, setResumeCount] = useState<number>(0);
  const { universityID, cohortID } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { data: university } = useSelfUniversity();
  const { data: cohorts } = useGetCohortsByUniversityID(
    universityID || university?.id,
    {
      enabled: !!universityID || !!university?.id, // Only fetch when a valid universityID is available
    },
  );
  const { data: resumeTemplates } = useGetResumeTemplates();
  const { openModal, closeModal } = useContext(ModalContext) as any;

  const isValid = /^([0-9]|[1-9][0-9]|100)$/.test(minScore);

  const mapTemplatesToOptions = (
    templates: ResumeTemplates[] | undefined,
  ): string[] => templates?.map((template) => template.name) || [];
  const templateIconMap = Object.fromEntries(
    resumeTemplates?.map(template => [template.name, template.preview_image]) || [],
  );

  const getResumeSelectionOptions = (): string[] => [
    'Take most recent resume',
    'Take resume with highest score',
  ];

  const handleBackButtonClick = () => {
    navigate(`/app/resume-os/students/${universityID}/${cohortID}`);
  };

  const resumeParams: ResumeBookParams = {
    cohort_ids: selectedCohorts.map(cohort => cohort.value) as string [],
    resume_template_id: resumeTemplates?.find(template => template.name === selectedTemplate)?.id || '',
    score: Number(minScore),
    more_than_one_preference: resumeSelectionPreference === 'Take resume with highest score' ? 'HIGHEST_SCORE' : 'RESENT',
    order_by: randomizeOrder ? 'RANDOM' : 'TITLE',
  };

  const areAllFieldsFilled = () => (
    selectedCohorts.length > 0
    && selectedTemplate !== ''
    && minScore !== ''
    && resumeSelectionPreference !== ''
  );

  const fetchResumeCount = async () => {
    try {
      const response = await getResumeBookCount(resumeParams);
      setResumeCount(response.count);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const debouncedFetchResumeCount = useMemo(
    () => debounce(fetchResumeCount, 300),
    [fetchResumeCount],
  );

  const downloadResumeBook = async (params: ResumeBookParams) => {
    const { response, contentDisposition } = await getResumeBook(params);
    const filename = contentDisposition.split('filename=')[1].trim().replace(/"/g, '');
    const blob = await response;
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const handleDownloadClick = async () => {
    setIsSubmitted(true);
    if (!areAllFieldsFilled()) {
      return;
    }
    openModal(
      <LoadingModal title="Generating Resume Book" orientation="horizontal" hasProgressBar />,
    );
    try {
      await new Promise(resolve => { setTimeout(resolve, 3000); });
      await downloadResumeBook(resumeParams);
      closeModal();
      openModal(
        <InfoModal
          title="Success!"
          icon="bi bi-file-earmark-check"
          description={(
            <>
              <p>Your export is being processed and downloaded in the background.</p>
              <p>This usually takes less than 1 minute.</p>
            </>
          )}
          buttonLabel="Okay"
          buttonIcon="bi bi-check-lg"
          handleButtonClick={() => {
            navigate(`/app/resume-os/students/${universityID}/${cohortID}`);
            closeModal();
          }}
          secondaryButtonLabel="Create another Export"
          secondaryButtonIcon="bi bi-arrow-left"
          handleSecondaryButtonClick={closeModal}
        />,
      );
    } catch (error) {
      Sentry.captureException(error);
      closeModal();
      openModal(
        <InfoModal
          title="Error"
          icon="bi bi-exclamation-triangle"
          description="An error occurred while generating the resume book. Please try again."
          buttonLabel="Okay"
          buttonIcon="bi bi-check-lg"
          handleButtonClick={closeModal}
        />,
      );
    }
  };

  const updateURLParams = () => {
    const params = new URLSearchParams(location.search);
    if (selectedCohorts.length) params.set('cohorts', selectedCohorts.map(cohort => cohort.value).join(','));
    if (selectedTemplate) params.set('template', selectedTemplate);
    if (minScore) params.set('minScore', minScore);
    if (resumeSelectionPreference) params.set('preference', resumeSelectionPreference);
    if (randomizeOrder) params.set('randomize', 'true');
    router.navigate(`?${params.toString()}`, { replace: true });
  };

  const loadStateFromURLParams = () => {
    const params = new URLSearchParams(location.search);
    const cohortsParam = params.get('cohorts');
    if (cohortsParam && cohorts) {
      const cohortIds = cohortsParam.split(',');
      const selectedCohortOptions = cohortIds
        .map(id => {
          const cohort = cohorts.find(c => c.id === id);
          return cohort ? { value: cohort.id, name: cohort.name } : null;
        })
        .filter((option): option is { value: string; name: string } => option !== null);
      setSelectedCohorts(selectedCohortOptions as FilterOption[]);
    } else {
      setSelectedCohorts(cohorts ? mapCohortsToFilterOptions(cohorts) : []);
    }

    const urlTemplate = params.get('template');
    setSelectedTemplate(getDefaultTemplateByUniversity(urlTemplate, resumeTemplates, university));
    setMinScore(params.get('minScore') || '70');
    setResumeSelectionPreference(params.get('preference') || 'Take most recent resume');
    setRandomizeOrder(params.get('randomize') === 'true');
  };

  useEffect(() => {
    if (areAllFieldsFilled()) {
      debouncedFetchResumeCount();
    } else {
      setResumeCount(0);
    }

    return () => {
      debouncedFetchResumeCount.cancel();
    };
  }, [selectedCohorts, selectedTemplate, minScore, resumeSelectionPreference]);

  useEffect(() => {
    updateURLParams();
  }, [selectedCohorts, selectedTemplate, minScore, resumeSelectionPreference, randomizeOrder, location.search]);

  useEffect(() => {
    loadStateFromURLParams();
  }, [location.search, cohorts, resumeTemplates, university]);

  return (
    <div className="resume-book-page">
      <div className="resume-book-page__container">
        <Button
          mode="invisible"
          size="medium"
          label="Go Back"
          icon="bi bi-arrow-left"
          onClick={handleBackButtonClick}
        />
        <div className="resume-book-page__top">
          <div className="resume-book-page__title">
            Export a resume book of your students!
          </div>
          <div className="resume-book-page__description">
            <p>Which resumes do you want to choose for your resume book?</p>
            <p>
              Select your criteria to include resumes below. Only Base Resumes
              are included in resume books.
            </p>
          </div>
        </div>
        <div className="resume-book-page__form">
          <MultiSelect
            placeholder="Select Cohorts"
            options={cohorts ? mapCohortsToFilterOptions(cohorts) : []}
            labelType="list"
            selected={selectedCohorts.map(cohort => ({ value: cohort.value, name: cohort.name }))}
            handleFiltering={(options) => setSelectedCohorts(options)}
            width="fit-box"
            size="large"
            label="Select one or multiple cohorts"
            isInvalid={isSubmitted && !selectedCohorts.length}
            warningMessage="This field is required"
            icon={university.logo_url}
            showIcon
            textSize="large"
          />
          <Select
            id="resume-book-page-template"
            options={mapTemplatesToOptions(resumeTemplates)}
            value={selectedTemplate}
            handleValueChange={(value) => setSelectedTemplate(value)}
            placeholder="Select resume template"
            label="Select the template the resumes should be formatted in"
            hint="Only resumes that were created and saved in that specific template will be included in your export."
            warningMessage="This field is required"
            isInvalid={isSubmitted && !selectedTemplate}
            icon={templateIconMap}
            showIcon
            textSize="large"
          />
          <Input
            id="resume-book-page-score"
            value={minScore}
            handleValueChange={(value) => setMinScore(value)}
            label="Set minimum ResumeOS score to be included"
            placeholder="e.g. 80"
            hint="Resumes below that number won't be included. Put “0” if you want to use all."
            warningText={!minScore ? 'This field is required' : 'The score should be between 0 and 100.'}
            invalid={isSubmitted && (!minScore || (!isValid && minScore !== ''))}
            inputSize="large"
          />
          <div className="resume-book-page__select-resume">
            <p className="resume-book-page__form-subtitle">
              What should happen for students with more than 1 resume?
            </p>
            <Select
              id="resume-book-page-select-resume"
              options={getResumeSelectionOptions()}
              value={resumeSelectionPreference}
              handleValueChange={(value) => setResumeSelectionPreference(value)}
              placeholder="Take most recent resume / take resume with highest score"
              label="Select preference"
              warningMessage="This field is required"
              isInvalid={isSubmitted && !resumeSelectionPreference}
              textSize="large"
            />
          </div>
          <Checkbox
            label="Randomize the order of resumes in the resume book (default: alphabetical)"
            checked={randomizeOrder}
            onChange={(checked) => setRandomizeOrder(checked)}
          />
        </div>
        <div className="resume-book-page__footer">
          <p>{`➔ Your request will result in ${resumeCount || 0} resumes`}</p>
        </div>
        <div className="resume-book-page__continue-button">
          <Tooltip label="Change your request details to include more than 0 resumes" position="top" disableHoverListener={resumeCount > 0}>
            <Button
              mode="primary"
              size="medium"
              label="Download"
              icon="bi bi-download"
              onClick={handleDownloadClick}
              disabled={resumeCount === 0}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

export default CustomWithAuthenticationRequired(ResumeBookPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
