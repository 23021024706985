import {
  useContext, useEffect, useRef, useState,
} from 'react';
import ReactQuill from 'react-quill';
import { useRevalidator } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { findTextInBrackets, sendingMessageToasts } from '@/services/helpers/messages';
import { useAnalytics } from '@/services/hooks/use-analytics';
import useDebounceEffect from '@/services/hooks/use-debounce';
import { useExtensionMessaging } from '@/services/hooks/use-extension-messaging';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';
import '@/services/helpers/quill-config';
import { CustomTemplatesModal, OutreachScenarios } from '@/domains/core/netwoking/components';
import { useOutreachScenario } from '@/services/hooks/use-outreach-scenario';
import { IconButton } from '@/components/icon-button/icon-button';
import { ContactPlaceholders, OutreachScenarioType, TemplateRequestPayload } from '@/domains/core/netwoking/types';
import { ModalContext } from '@/components/modal/modal-provider';
import { Button } from '@/components/button/Button';
import './connection-box.scss';
import { TemplatesButton } from '../templates-button/templates-button';
import { sendMessage } from '@/services/api/contact';
import { CatolicaTemplate } from '../outreach-scenarios/scenarios';
import { useSelfUniversity } from '@/services/queries/user';

type Props = {
  contactPlaceholders: ContactPlaceholders;
  messageTemplate?: string;
  contactId: string;
  toUserLinkedInUrn: string;
  handleSentMesage: () => void;
  handleConnectionSent: () => void;
  regenerateData: TemplateRequestPayload;
  reopenBox: (message: string) => void;
};

const MAX_MESSAGE_LENGTH = 200;
const MAX_MESSAGE_LENGTH_PREMIUM = 300;
const MIN_MESSAGE_LENGTH = 25;

export const ConnectionBox = ({
  contactPlaceholders,
  messageTemplate,
  contactId,
  toUserLinkedInUrn,
  handleConnectionSent,
  handleSentMesage,
  reopenBox,
  regenerateData,
}: Props) => {
  const quillEditor = useRef<any>(null);
  const reactQuillRef = useRef<ReactQuill | null>(null);
  const { addToast }: AddToastType = useContext(ToastContext);
  const {
    getPremiumStatus,
    sendLinkedInConnection,
  } = useExtensionMessaging();
  const [message, setMessage] = useState<any>('');
  const {
    showScenarios,
    regenerateScenario,
    isLoadingOutreachScenario,
    hideScenarios,
    scenarioTemplate,
    showRegenerateScenario,
    displayScenarios,
    cancelRegenerateScenario,
  } = useOutreachScenario();
  const [isSendingDisabled, setIsSendingDisabled] = useState(true);
  const { openModal } = useContext(ModalContext) as any;
  const [warningMessage, setWarningMessage] = useState<string>();
  const [messageLength, setMessageLength] = useState(0);
  const { trackEvent } = useAnalytics();
  const [premiumStatus, setPremiumStatus] = useState(false);
  const { data: univeristy } = useSelfUniversity();
  const queryClient = useQueryClient();
  const revalidator = useRevalidator();
  const limit = premiumStatus ? MAX_MESSAGE_LENGTH_PREMIUM : MAX_MESSAGE_LENGTH;

  const applyTemplate = (template: string) => {
    reopenBox(template);
    hideScenarios();
  };

  const handleCustomTemplatesClick = () => {
    openModal(
      <CustomTemplatesModal
        contactPlaceholders={contactPlaceholders}
        previewType="connection"
        selectTemplate={applyTemplate}
        hasLinkedInPremium={premiumStatus}
      />,
      {
        closeOnClickOutside: false,
      },
    );
  };
  const checkPremiumProfile = async () => {
    try {
      const status = await getPremiumStatus();
      if (status) {
        setPremiumStatus(status);
      }
    } catch {
      trackEvent('LinkedIn extension not installed');
    }
  };

  const installExtensionToast = () => {
    addToast(
      {
        type: 'warning',
        message: 'It looks like you don\'t have our LinkedIn extension installed.',
        additionalMessage: 'Please install it to be able to send LinkedIn messages.',
      },
    );
  };

  const handleWriteMessageSelection = () => {
    hideScenarios();
    setMessage('');
  };

  const handleSendConnectionRequest = async (messageText: string) => {
    const connectionResult = await sendLinkedInConnection(toUserLinkedInUrn, messageText);
    sendMessage(contactId);
    queryClient.invalidateQueries('quests');
    if (!connectionResult) {
      installExtensionToast();
      throw new Error('Failed to send connection request');
    }

    return connectionResult;
  };

  const handleMessageChange = (value: string) => {
    const textLength = quillEditor.current?.getText().trim().length;
    setMessage(value);
    setMessageLength(textLength);
  };

  const checkMessagePlaceholders = () => {
    const hasPlaceholders = message.match(/\[[^[\]]*\]/g);

    return hasPlaceholders;
  };

  const checkMessage = () => {
    if (checkMessagePlaceholders()) {
      setIsSendingDisabled(true);
      setWarningMessage('Please make sure to replace the placeholders in brackets with the according information.');

      return false;
    }

    if (messageLength > limit) {
      setIsSendingDisabled(true);
      setWarningMessage(`Please make sure your message does not exceed ${limit} characters.`);

      return false;
    }

    if (messageLength < MIN_MESSAGE_LENGTH) {
      setIsSendingDisabled(true);
      setWarningMessage('Please send a personalized outreach message with at least 25 characters.');

      return false;
    }

    setIsSendingDisabled(false);
    setWarningMessage(undefined);

    return true;
  };

  const successMessageSendToast = () => {
    addToast(
      {
        type: 'success',
        message: sendingMessageToasts.success.connection.message,
        additionalMessage: sendingMessageToasts.success.connection.additionalMessage,
      },
    );
  };

  const errorMessageSendToast = (errorMessage?: string) => {
    if (errorMessage === 'You have reached the maximum number of pending invitations.') {
      addToast({
        type: 'error',
        message: 'You have reached your weekly LinkedIn invitation limit.',
        additionalMessage: 'This limit is set by Linkedin and will reset once per week. Upgrade to Linkedin premium or use our email networking features to keep networking in the meantime.',
      });
      trackEvent('Toast Error Shown', {}, {
        message: 'You have reached your weekly LinkedIn invitation limit',
        error: errorMessage,
      });
      return;
    }

    addToast({
      type: 'error',
      message: errorMessage || sendingMessageToasts.error.connection.message,
      additionalMessage: sendingMessageToasts.error.connection.additionalMessage,
    });
    trackEvent('Toast Error Shown', {}, {
      message: errorMessage || sendingMessageToasts.error.connection.message,
      error: sendingMessageToasts.error.connection.additionalMessage,
    });
  };

  const handleSendMessageClick = async () => {
    const messageText = quillEditor.current.getText().trim();

    if (!checkMessage()) {
      return;
    }

    try {
      await handleSendConnectionRequest(messageText);
      handleConnectionSent();

      trackEvent('Message Sent');
      successMessageSendToast();
      setMessage('');

      handleSentMesage();

      revalidator.revalidate();
    } catch (error) {
      const errorMessage = typeof error === 'string' ? error : undefined;
      errorMessageSendToast(errorMessage);
      trackEvent('LinkedIn connection send failed', {}, { errorMessage });
    }
  };

  const attachQuillRefs = () => {
    if (!reactQuillRef.current) {
      return;
    }
    if (typeof reactQuillRef.current.getEditor !== 'function') return;
    quillEditor.current = reactQuillRef.current.getEditor();
  };

  const highlightPlaceholders = () => {
    if (!reactQuillRef.current) {
      return;
    }
    const bracketedParts = findTextInBrackets(quillEditor.current.getText());
    quillEditor.current.formatText(0, quillEditor.current.getText().length, {
      color: false,
      bold: false,
    });
    bracketedParts.forEach(part => {
      quillEditor.current.formatText(part.index, part.length, {
        color: 'var(--purpose-information)',
        bold: true,
      });
    });
  };

  const checkForCatolica = (sc: OutreachScenarioType) => {
    if (sc === 'meet_at_in_person_career_fair' && univeristy?.name === 'Católica Lisbon School of Business') {
      setMessage(CatolicaTemplate(regenerateData?.studentName || '[Your First Name]'));
      hideScenarios();
      return;
    }
    regenerateScenario({ ...regenerateData, maxCharacters: limit }, sc);
  };

  useDebounceEffect(() => {
    highlightPlaceholders();
    setIsSendingDisabled(messageLength === 0);

    if (warningMessage) {
      checkMessage();
    }
  }, [message]);

  useEffect(() => {
    if (scenarioTemplate && !isLoadingOutreachScenario) {
      setMessage(scenarioTemplate);
    }
  }, [scenarioTemplate, isLoadingOutreachScenario]);

  useEffect(() => {
    if (messageTemplate && reactQuillRef.current) {
      handleMessageChange(messageTemplate);
      hideScenarios();
    }
  }, [messageTemplate, reactQuillRef.current]);

  useEffect(() => {
    attachQuillRefs();
    checkPremiumProfile();
    displayScenarios();
  }, []);

  return (
    <div data-testid="connection-box" className="connection-box">
      <h3 className="connection-box__title">
        Personalize your invitation with a note
      </h3>
      <div className={`connection-box__container ${warningMessage ? 'connection-box__container--dangerous' : ''}`}>
        {(showScenarios || isLoadingOutreachScenario) && (
          <div className="connection-box__scenarios" data-testid="connection-box-scenarios">
            <OutreachScenarios
              handleApplyTemplate={(scenario) => checkForCatolica(scenario)}
              handleWriteMessageSelection={handleWriteMessageSelection}
              isLoading={isLoadingOutreachScenario}
            />
          </div>
        )}
        <ReactQuill
          ref={reactQuillRef}
          data-testid="connection-box-input"
          placeholder="Write a personalized message to your contact"
          modules={{
            toolbar: null,
          }}
          theme="snow"
          value={message}
          onChange={handleMessageChange}
          style={{
            border: 'none',
            padding: '0',
            borderRadius: '8px',
            height: 76,
            display: (showScenarios || isLoadingOutreachScenario) ? 'none' : 'block',
          }}
        />
      </div>
      <div
        className="connection-box__bottom"
        data-testid="connection-box-bottom"
      >
        {warningMessage && (
          <span className="email-box__warning" data-testid="connection-box-warning">
            {warningMessage}
          </span>
        )}
        <span
          className={`connection-box__length ${warningMessage ? 'connection-box__length--dangerous' : ''}`}
          data-testid="connection-box-length-count"
        >
          {messageLength}
          /
          {limit}
        </span>
      </div>
      <div className="connection-box__footer">
        <div className="connection-box__cta">
          <TemplatesButton
            showOutreachButton={!showScenarios && !isLoadingOutreachScenario}
            triggerOutreachTemplateButton={displayScenarios}
            triggerCustomTemplateButton={handleCustomTemplatesClick}
            isIconButton={!showScenarios}
          />
          {showRegenerateScenario && (
            <IconButton
              data-testid="connection-box-regenerate-button"
              label="Regenerate message"
              icon={isLoadingOutreachScenario ? 'bi bi-x-lg' : 'bi bi-arrow-clockwise'}
              onClick={() => (isLoadingOutreachScenario ? cancelRegenerateScenario() : regenerateScenario({ ...regenerateData, maxCharacters: limit }))}
              mode="special"
              size="large"
              outlined
            />
          )}
          <Button
            label="Send LinkedIn Connection Request"
            icon="bi bi-send"
            onClick={handleSendMessageClick}
            mode="primary"
            size="medium"
            disabled={isSendingDisabled}
            data-testid="connection-box-send-button"
          />
        </div>
      </div>
    </div>
  );
};
