import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';

import { KanbanTile } from './tile/kanban-tile';
import { useScroll } from '@/services/hooks/use-scroll';
import ChromeLogo from '@/assets/images/chrome-icon.png';
import './kanban-board.scss';
import { FilterNameType, FilterOption, Tile } from '@/domains/core/company/types';
import { KanbanFilterBar } from './filter/filter-bar';
import { Counter } from '@/components/counter/counter';
import { Banner } from '@/components/banner/banner';
import { useExtensionMessaging } from '@/services/hooks/use-extension-messaging';
import { IconButton } from '@/components/icon-button/icon-button';
import { Button } from '@/components/button/Button';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { ColumnConfetti } from './column-confetti/column-confetti';
import Confetti from '../confetti/confetti';

interface KanbanBoardProps {
  columns: string[];
  layout: any;
  onChange: any;
  handleFiltersChange: (filters: { [name in FilterNameType]: FilterOption[] }, searchQuery: string) => void;
  savedIndustries: string[];
  readOnly?: boolean;
}

export const KanbanBoard = ({
  columns,
  layout,
  onChange,
  savedIndustries,
  handleFiltersChange,
  readOnly = false,
}: KanbanBoardProps) => {
  const { trackEvent } = useAnalytics();
  const [kanbanContainer, handleVerticalScroll] = useScroll();
  const [showRightScrollArrow, setShowRightScrollArrow] = useState(true);
  const [showLeftScrollArrow, setShowLeftScrollArrow] = useState(false);
  const [promptToInstallExtension, setPromptToInstallExtension] = useState(false);
  const { checkLinkedInLogin } = useExtensionMessaging();
  const [offerAnimationCount, setOfferAnimationCount] = useState(0);
  const [newInterviewStageTileIds, setNewInterviewStageTileIds] = useState<string[]>([]);
  const navigate = useNavigate();

  const checkUserExtension = async () => {
    if (readOnly) {
      setPromptToInstallExtension(false);
      return;
    }

    const linkedInLoginStatus = await checkLinkedInLogin();

    if (!linkedInLoginStatus) {
      setPromptToInstallExtension(true);
    }
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const el = e.currentTarget;
    const allColumnsWidth = kanbanContainer.current.scrollWidth;
    const columnsInViewWidth = kanbanContainer.current.clientWidth;

    if (Math.round(el.scrollLeft + columnsInViewWidth) >= Math.round(allColumnsWidth)) {
      setShowRightScrollArrow(false);
    } else {
      setShowRightScrollArrow(true);
    }
    if (el.scrollLeft === 0) {
      setShowLeftScrollArrow(false);
    } else {
      setShowLeftScrollArrow(true);
    }
  };

  const onDragEnd = (result: any) => {
    const { source, destination, draggableId } = result;

    const payload = {
      id: draggableId,
      status: source.droppableId,
      dropPosition: destination.index,
    };
    onChange(payload, destination.droppableId);

    if (destination.droppableId === payload.status) {
      return;
    }

    setNewInterviewStageTileIds((prev) => prev.filter((id) => id !== draggableId));

    if (destination.droppableId === 'Offer') {
      setOfferAnimationCount(prev => prev + 1);
    }

    if (destination.droppableId === 'Interviewing') {
      setNewInterviewStageTileIds(prev => [...prev, draggableId]);
    }
  };

  const handleGetExtensionClick = () => {
    window.open(import.meta.env.VITE_EXTENSION_LINK, '_blank');
    trackEvent('Get Extension on OB banner clicked');
  };

  useEffect(() => {
    checkUserExtension();
  }, []);

  return (
    <div className="kanban-board">
      <div className="kanban-board__filters">
        <KanbanFilterBar handleFiltersChange={handleFiltersChange} industries={savedIndustries} />
      </div>
      <div className="controls-wrapper">
        <div className="kanban-board__controls">
          <IconButton
            icon="bi bi-chevron-left"
            mode="rounded"
            size="medium"
            outlined
            handleClick={() => handleVerticalScroll('left')}
            disabled={!showLeftScrollArrow}
          />
          <IconButton
            icon="bi bi-chevron-right"
            mode="rounded"
            size="medium"
            outlined
            handleClick={() => handleVerticalScroll('right')}
            disabled={!showRightScrollArrow}
          />
        </div>
      </div>
      {promptToInstallExtension && (
        <div className="kanban-board__banner">
          <Banner
            buttonLabel="Get Extension"
            handleButtonClick={handleGetExtensionClick}
            image={ChromeLogo}
            buttonPlacement="horizontal"
            buttonIcon="bi bi-box-arrow-up-right"
            buttonIconPosition="right"
            title="Save jobs, contacts and companies from LinkedIn and other job boards to bring all into one place with our Chrome Extension."
          />
        </div>
      )}
      <div className="kanban-board__content" ref={kanbanContainer} onScroll={handleScroll}>
        <DragDropContext onDragEnd={onDragEnd}>
          {columns.map((column, index) => (
            <div
              key={column}
              className={`kanban-board__column kanban-board__column--nr-${index}`}
            >
              {column === 'Offer' && (
                <div className="kanban-board__confetti">
                  <ColumnConfetti fireCount={offerAnimationCount} />
                </div>
              )}
              <div className="kanban-board__column__title">
                <span className="kanban-board__column__title__text">{column}</span>
                <span className="kanban-board__title-number">
                  <Counter counter={layout[column] && layout[column].length} size="medium" mode="default" />
                </span>
              </div>
              {index === 0 && !readOnly && (
              <div className="kanban-board__column__cta">
                <Button
                  label="Add Company"
                  icon="bi bi-plus-circle"
                  handleClick={() => navigate('/app/companies/explore')}
                  mode="invisible"
                  size="large"
                />
              </div>
              )}
              <Droppable key={column} droppableId={column}>
                {(providedDrop) => (
                  <div
                    className="kanban-board__column__drop-zone"
                    ref={providedDrop.innerRef}
                    {...providedDrop.droppableProps}
                  >
                    {layout[column] && layout[column].map((tile: Tile, idx: number) => (
                      <Draggable key={tile.id} draggableId={tile.id} index={idx}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div className="kanban-board__tile">
                              {newInterviewStageTileIds.includes(tile.id) && (
                                <div className="kanban-board__confetti">
                                  <Confetti fireCount={1} emojis={['🤞', '🚀']} duration={500} />
                                </div>
                              )}
                              <KanbanTile
                                key={tile.id}
                                tile={tile}
                                readOnly={readOnly}
                              />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {providedDrop.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          ))}
        </DragDropContext>
      </div>
    </div>
  );
};
