import { Tooltip } from '@/components/tooltip/tooltip';
import './tile-cta.scss';

type Props = {
  label: string;
  icon: string;
  handleClick: () => void;
  counter?: number;
};

export const TileCta = ({
  label,
  icon,
  handleClick,
  counter,
}: Props) => {
  const onClick = (ev: React.MouseEvent) => {
    ev.preventDefault();
    handleClick();
  };

  return (
    <div className="tile-cta" onClick={onClick}>
      <Tooltip label={label} withArrow position="top">
        <div className="tile-cta__container">
          <i className={`tile-cta__icon ${icon}`} />
          {counter !== undefined && (
            <span
              className={`tile-cta__counter tile-cta__counter--${counter}`}
            >
              {counter}
            </span>
          )}
        </div>
      </Tooltip>
    </div>
  );
};
