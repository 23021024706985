import {
  useContext, useEffect, useRef, useState,
} from 'react';
import { createPortal } from 'react-dom';
import { Company } from '../../types';
import { getSingleCompany } from '@/services/api/company';
import { Loader } from '@/components/loader/loader';
import { TrackerContextType, TrackerContext } from './tracker-context';
import './tracker-overlay.scss';
import SingleCompanyTrackerModal from '../tracker-modal/tracker-modal';
import { useAnalytics } from '@/services/hooks/use-analytics';

const CLOSING_ANIMATION_DURATION = 300;

// TODO: find a better way to solve problem with root
const trackerRoot = document.getElementById('tracker-root') as HTMLElement || document.getElementById('storybook-root') as HTMLElement;
const TrackerLoaderWrapper = () => {
  const {
    isTrackerModal, closeTracker, companyID, trackerTab,
  } = useContext(TrackerContext) as TrackerContextType;
  const { trackEvent } = useAnalytics();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [company, setCompany] = useState<Company>();
  const [status, setStatus] = useState<string>('');
  const [closing, setClosing] = useState<boolean>(false);
  const boxRef = useRef<HTMLDivElement>(null);

  const handleCloseClick = () => {
    setClosing(true);
    setTimeout(() => {
      closeTracker();
      setClosing(false);
    }, CLOSING_ANIMATION_DURATION);
  };

  const trackerLoader = async () => {
    try {
      if (!companyID) {
        setIsError(true);
        return;
      }

      const companyResponse = await getSingleCompany(companyID);

      if (!companyResponse || companyResponse.error) {
        throw new Error('Company not found', companyResponse.error);
      }

      setIsError(false);
      trackEvent('Tracker Modal Opened', {}, { companyID });
      setCompany(companyResponse);
      setStatus(companyResponse.application_status || 'Company Saved');
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBlur = (e: any) => {
    const { target } = e;

    if (boxRef.current === target) {
      handleCloseClick();
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (isTrackerModal && companyID) {
      trackerLoader();
    }
  }, [companyID, isTrackerModal]);

  useEffect(() => {
    document.addEventListener('mousedown', handleBlur);

    return () => document.removeEventListener('mousedown', handleBlur);
  }, []);

  if (isTrackerModal) {
    return createPortal(
      <div
        className="tracker-overlay"
        ref={boxRef}
        tabIndex={-1}
      >
        {isLoading && (
        <div className="tracker-overlay__loader">
          <Loader />
        </div>
        )}
        <div onClick={(e) => e.stopPropagation()} className="tracker-overlay__content">
          {(isError && !isLoading) && (
          <div className="tracker-overlay__error">
            <h3>Sorry, something went wrong</h3>
            <p>We couldn&apos;t load the company tracker</p>
          </div>
          )}
          {!isLoading && !isError && company && companyID && (
            <>
              <div className={`tracker-overlay__box ${closing ? 'tracker-overlay__box--closing' : ''}`}>
                <div className="tracker-overlay__close-button-container">
                  <div
                    className="tracker-overlay__close-button"
                    onClick={handleCloseClick}
                  >
                    <i className="tracker-overlay__close-icon bi bi-x-circle" />
                    <i className="tracker-overlay__close-icon tracker-overlay__close-icon--hover bi bi-x-circle-fill" />
                  </div>
                </div>
                <SingleCompanyTrackerModal company={company} status={status} companyID={companyID} openTab={trackerTab} revalidate={trackerLoader} />
              </div>
              <div className="tracker-overlay__box tracker-overlay__box--transparent" />
            </>
          )}
        </div>
      </div>,
      trackerRoot,
    );
  } return null;
};

export default TrackerLoaderWrapper;
