import { getStudentActionPlan, getStudentByID, getUniversity } from '@/services/api/student';
import { Student } from '../types';
import { getAllStudents } from '@/services/api/university';

export async function studentsLoader() {
  const universityResponse = await getUniversity();
  let students: Student[] = [];

  if (universityResponse) {
    const studentsResponse: any = await getAllStudents(universityResponse.id);
    students = studentsResponse && studentsResponse?.length > 0 ? studentsResponse.map((student: any) => ({
      ...student,
      name: `${student.first_name} ${student.last_name}`,
      type: 'MBA Student',
      logged_in: true,
      universityID: universityResponse.id,
    })) : [];
  }

  return { students };
}

export async function actionPlanByStudentLoader(request: any) {
  const { studentID } = request.params;
  const student = await getStudentByID(studentID);
  const actionPlan = await getStudentActionPlan(studentID);

  return {
    student, planA: actionPlan?.planA, planB: actionPlan?.planB, planC: actionPlan?.planC,
  };
}
