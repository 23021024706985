import { useQuery } from 'react-query';
import { getAllStudentsByCohortID, getAllStudentsResumesByCohortID, getCohortsByUniversityID } from '../api/university';
import {
  getCombinedResume, getStudentByID, getStudentComments, getStudentProfile,
} from '../api/student';
import { getSelfComments } from '../api/user';

export const useGetStudentsByCohort = (cohortID: string, options = {}) => useQuery(
  ['cohort', cohortID],
  async () => {
    const response = await getAllStudentsByCohortID(cohortID);
    return response ?? [];
  },
  {
    retry: 0,
    staleTime: 1000 * 60 * 60 * 8, // refetch in the background every 8 hours
    ...options, // Spread additional options
  },
);

export const useGetAllStudentResumesQueries = (studentID: string, options = {}) => useQuery(
  ['combinedResume', studentID],
  async () => {
    const response = await getCombinedResume(studentID);
    return response ?? [];
  },
  {
    retry: 0,
    staleTime: 1000 * 60 * 60 * 8, // refetch in the background every 8 hours
    ...options, // Spread additional options
  },
);

export const useGetStudentProfileData = (studentID: string, options = {}) => useQuery(
  ['studentProfile', studentID],
  async () => {
    const response = await getStudentProfile(studentID);
    return response ?? [];
  },
  {
    retry: 0,
    staleTime: 1000 * 60 * 60 * 8, // refetch in the background every 8 hours
    ...options, // Spread additional options
  },
);

export const useGetStudentsResumesByCohort = (cohortID: string, options = {}) => useQuery(
  ['cohort-resumes', cohortID],
  async () => {
    const response = await getAllStudentsResumesByCohortID(cohortID);
    return response ?? [];
  },
  {
    retry: 0,
    staleTime: 1000 * 60 * 60 * 8, // refetch in the background every 8 hours
    ...options, // Spread additional options
  },
);

export const useGetCohortsByUniversityID = (universityID: string, options = {}) => useQuery(
  ['universityCohorts', universityID], // Use an array with 'cohort' and the actual cohortID as the query key
  () => getCohortsByUniversityID(universityID), // Use an arrow function to call your actual function with the cohortID
  {
    retry: 0,
    // should be refetched in the background every 8 hours
    staleTime: 1000 * 60 * 60 * 8,
    ...options, // Spread additional options
  },
);

export const useStudentByID = (studentID: string, options = {}) => useQuery(
  ['studentData', studentID], // Use an array with 'cohort' and the actual cohortID as the query key
  () => getStudentByID(studentID), // Use an arrow function to call your actual function with the cohortID
  {
    retry: 0,
    // should be refetched in the background every 8 hours
    staleTime: 1000 * 60 * 60 * 8,
    ...options, // Spread additional options
  },
);

export const useStudentComments = (studentID: string | undefined, options = {}) => useQuery(
  ['studentComments', studentID],
  () => (studentID ? getStudentComments(studentID) : getSelfComments()),
  {
    retry: 0,
    // should be refetched in the background every 8 hours
    staleTime: 1000 * 60 * 60 * 1,
    ...options, // Spread additional options
  },
);
