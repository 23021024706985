import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createRoot } from 'react-dom/client';
import ConfettiExplosion from 'react-confetti-explosion';
import { useAuth0 } from '@auth0/auth0-react';
import { Timestamp } from 'firebase/firestore';
import { useSelf } from '@/services/queries/user';
import { IconButton } from '@/components/icon-button/icon-button';
import { Contact } from '@/domains/core/contact/types';
import { LinkedinActions } from '../linkedin-actions/linkedin-actions';
import { LinkedinChat } from '../linkedin-chat/linkedin-chat';
import { ChatBoxEmptyStateBanner } from '../chat-box-empty-state-banner/chat-box-empty-state-banner';
import { FirebaseLinkedinMessage } from '../../types/messages';
import InboxEmptyStateImage from '@/assets/images/inbox/inbox-empty-state.jpg';
import '../chat-box/chat-box.scss';
import './chat-box-empty-state.scss';
import { ToggleViews } from '@/components/toggle-views/toggle-views';

export const contact: Contact = {
  first_name: 'Jerome',
  last_name: 'Doe',
  id: '123456789',
  column: 0,
  updated_at: '2024-07-09T12:00:00Z',
  kanban_position: {
    column: 0,
    row: 0,
  },
  current_position: {
    company: 'Tech Corp',
    company_id: '987654321',
    location: 'San Francisco, CA',
    title: 'Software Engineer',
    company_logo_url: 'https://example.com/logo.png',
  },
  career_summary: {
    basics: {
      email: 'jerome.doe@example.com',
      image: 'https://example.com/photo.jpg',
      label: 'Software Engineer',
      name: 'Jerome Doe',
      summary:
        'Experienced Software Engineer with a demonstrated history of working in the tech industry.',
      url: 'https://www.linkedin.com/in/jeromedoe',
    },
    skills: [
      { name: 'JavaScript', level: 'Expert' },
      { name: 'Python', level: 'Advanced' },
    ],
    languages: [
      { language: 'English', fluency: 'Native' },
      { language: 'Spanish', fluency: 'Intermediate' },
    ],
    education: [
      {
        institution: 'University of Example',
        area: 'Computer Science',
        studyType: "Bachelor's",
        startDate: '2015-09-01',
        endDate: '2019-06-15',
        score: '3.8 GPA',
        courses: ['Algorithms', 'Data Structures', 'Machine Learning'],
      },
    ],
    work: [
      {
        name: 'Tech Corp',
        position: 'Software Engineer',
        startDate: '2020-01-10',
        endDate: '2024-07-09',
        location: 'San Francisco, CA',
        url: 'https://techcorp.com',
        summary:
          'Developed and maintained web applications using JavaScript and Python.',
      },
    ],
  },
  linkedin_url: 'https://www.linkedin.com/in/jeromedoe',
  company_id: '987654321',
  email: 'jerome.doe@example.com',
  chat_id: 'chat12345',
  linkedin_urn: 'urn:li:person:1234abcd',
  linkedin_connection_updated_at: '2024-07-09T12:00:00Z',
  linkedin_connection_status: 'connected',
  saved_at: '2024-07-01T09:30:00Z',
  secondary_email: 'jerome.doe.secondary@example.com',
  active_quests: [],
  completed_quests: [],
};

export const ChatBoxEmpty = () => {
  const { user } = useAuth0();
  const { data: self, isLoading: isSelfLoading } = useSelf();
  const [fakeMessages, setFakeMessages] = useState<FirebaseLinkedinMessage[]>([]);
  const [image, setImage] = useState<string>('');
  const [step, setStep] = useState(0);
  const confettiContainerRef = useRef(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const resetState = () => {
    setFakeMessages([]);
    setImage('');
    setStep(0);
  };
  // eslint-disable-next-line arrow-body-style
  const renderConfetti = useCallback(() => {
    if (confettiContainerRef.current) {
      const root = createRoot(confettiContainerRef.current);
      root.render(<ConfettiExplosion force={0.8} duration={2000} particleCount={500} />);

      setTimeout(() => {
        root.unmount();
      }, 2000);
    }
  }, []);

  const simulateConversation = (currentUser: any) => {
    const fakeConversation: FirebaseLinkedinMessage[] = [
      {
        fromContactID: null,
        fromUserID: currentUser.id,
        text: `Hi Jerome, I hope you’re well. I’m currently a student at Esade and really admire McKinsey’s work, particularly in consulting, and would love to learn more about your experiences there. Would you be open to a brief coffee chat? I’d greatly appreciate any insights you could share. Best regards, ${currentUser.first_name}`,
        createdAt: Timestamp.fromDate(new Date('2024-07-04T18:43:00')),
      },
      {
        fromContactID: null,
        fromUserID: currentUser.id,
        text: `Hi Jerome, I just wanted to follow up on my previous message. If you’re still keen to share some insights with me, how would Monday at 12:00 sound? Thanks, ${currentUser.first_name}`,
        createdAt: Timestamp.fromDate(new Date('2024-07-07T10:15:00')),
      },
      {
        fromContactID: '123456789',
        fromUserID: null,
        text: `Hi ${currentUser.first_name}, Of course, I would be more than happy to spend a few minutes discussing this. Monday at 12:00 works for me. Thanks, Jerome`,
        createdAt: Timestamp.fromDate(new Date('2024-07-07T15:31:00')),
      },
      {
        fromContactID: null,
        fromUserID: currentUser.id,
        text: 'Great! I’ve sent you an invite to the meeting. Looking forward to our chat!',
        createdAt: Timestamp.fromDate(new Date('2024-07-07T19:20:00')),
      },
    ];

    const scenarioSteps = [
      () => {
        setStep(0);
        setStep(1);
        setFakeMessages((prevMessages) => [
          ...prevMessages,
          fakeConversation[0],
        ]);
      },
      () => {
        setImage(InboxEmptyStateImage);
      },
      () => {
        setImage('');
      },
      () => {
        setFakeMessages((prevMessages) => [
          ...prevMessages,
          fakeConversation[1],
        ]);
      },
      () => {
        setStep(2);
        setFakeMessages((prevMessages) => [
          ...prevMessages,
          fakeConversation[2],
        ]);
      },
      () => {
        renderConfetti();
        setStep(3);
        setFakeMessages((prevMessages) => [
          ...prevMessages,
          fakeConversation[3],
        ]);
      },
      () => {
        setStep(4);
        setFakeMessages((prevMessages) => [
          ...prevMessages,
          fakeConversation[4],
        ]);
      },
      () => {},
    ];

    const executeActionsSequentially = (actions: (() => void)[], index = 0) => {
      if (index >= actions.length - 1) {
        resetState();
        simulateConversation(currentUser);
        return;
      }

      const action = actions[index];
      setTimeout(() => {
        action();
        executeActionsSequentially(actions, index + 1);
      }, 2000);
    };

    executeActionsSequentially(scenarioSteps);
  };

  useEffect(() => {
    if (!isSelfLoading && self) {
      simulateConversation(self);
    }
  }, [isSelfLoading, self, user]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [fakeMessages, image]);

  return (
    <div className="chat-box chat-box-empty-state">
      <div className="chat-box__header chat-box-empty-state__header">
        <div className="chat-box-empty-state__info">
          <h2 className="chat-box-empty-state__name">Jerome Bell</h2>
          <h3 className="chat-box-empty-state__position">
            Consultant at McKinsey & Company
          </h3>
        </div>
        <div className="chat-box-empty-state__actions">
          <ToggleViews labels={['Linkedin', 'Email']} size="small" selectedValue={0} emitSelectedValue={() => {}} icons={['bi-linkedin', 'bi-envelope']} iconsSelected={['bi-linkedin', 'bi-envelope-fill']} />
          <IconButton
            icon="bi bi-chevron-left"
            onClick={() => {}}
            outlined
            mode="rounded"
            size="large"
          />
        </div>
        <ChatBoxEmptyStateBanner activeStep={step} />
      </div>
      <div className="chat-box__content chat-box-empty-state__content">
        <div ref={confettiContainerRef} className="chat-box-empty-state__confetti" />
        {image ? (
          <img
            className="chat-box-empty-state__image"
            src={InboxEmptyStateImage}
            alt="3 days later"
          />
        ) : (
          user && self && (
          <LinkedinChat
            messages={fakeMessages}
            user={user}
            contact={contact}
            selfID={self.id}
          />
          )
        )}
        <div ref={messagesEndRef} />
      </div>
      <div className="chat-box__footer chat-box-empty-state__footer">
        <LinkedinActions
          contactID="123456789"
          sendMessage={() => {}}
          contactName="Jerome Bell"
          disabled
        />
      </div>
    </div>
  );
};
