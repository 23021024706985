import { useEffect, useState } from 'react';
import {
  Link,
  useLoaderData,
  useNavigate,
  useParams,
} from 'react-router-dom';

import {
  CompanyCard,
  CategoryWidget,
  CompaniesFilters,
  EmptySearchResult,
  CategoryWidgetSkeleton,
} from '@/domains/core/company';
import { Loader } from '@/components/loader/loader';
import { Button } from '@/components/button/Button';
import { Banner } from '@/components/banner/banner';
import { IndustryFilters } from '@/components/industry-filters/industry-filters';

import './styles/explore-companies.scss';
import { NUMBER_OF_COMPANIES_WITH_PULSE_ANIMATION } from '@/services/helpers/company';
import { getExploreCompanies } from '@/services/api/company';
import CustomWithAuthenticationRequired from './auth/custom-protected-route';
import { useCheckExtension } from '@/services/hooks/use-check-extension';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { SearchBar } from '@/domains/generic/search';
import {
  ExploreCompaniesResponse,
  Category,
  Company,
  FilterCompaniesRequestBody,
} from '@/domains/core/company/types';
import { useSelfUniversity } from '@/services/queries/user';
import { USUniversities } from '@/domains/core/student/constants';
import USFlag from '@/assets/images/us-flag.png';
import { isMobileDevice } from '@/services/helpers/responsive';

function ExploreCompaniesPage() {
  const params = useParams();
  const { industryName } = params;
  const navigate = useNavigate();
  const { trackEvent } = useAnalytics();
  const { data: university, isLoading: isUniversityLoading } = useSelfUniversity();
  const [isNullIndustry, setIsNullIndustry] = useState(!industryName || industryName === 'Featured');
  const [showBankingBanner, setShowBankingBanner] = useState(true);
  const [showFinancialServicesBanner, setShowFinancialServicesBanner] = useState(true);
  const [hasNotSavedCompanies, setHasNotSavedCompanies] = useState(false);
  const [currentIndustryIcon, setCurrentIndustryIcon] = useState('');
  const [numberOfSavedCompanies, setNumberOfSavedCompanies] = useState(0);
  const {
    industries, applications, archivedCompanies, locations, sizes, tags,
  } = useLoaderData() as ExploreCompaniesResponse;
  const [isLoading, setIsLoading] = useState(true);
  const [filteredCategories, setFilteredCategories] = useState<Category[]>([]);
  const [showH1BBanner, setShowH1BBanner] = useState(false);
  const { checkUserExtension } = useCheckExtension();

  const checkUniversity = () => {
    if (university?.name && USUniversities.includes(university.name)) {
      setShowH1BBanner(true);
    } else {
      setShowH1BBanner(false);
    }
  };

  const getFilteredCompaniesFromAPI = async () => {
    setIsLoading(true);
    setFilteredCategories([]);
    const defaultFilters: FilterCompaniesRequestBody = {
      saved_by_user: false,
    };
    try {
      const filteredResult = await getExploreCompanies(industryName || 'Featured', defaultFilters);
      if (Array.isArray(filteredResult)) {
        setFilteredCategories(filteredResult);
      } else {
        setFilteredCategories([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoToAdvancedSearch = () => {
    navigate('/app/companies');
    trackEvent('Advanced Search Button Clicked', { location: 'Explore Companies Page' });
  };

  const handleFiltering = async (filters: URLSearchParams) => {
    if (filters.toString() === '') {
      return;
    }

    if (isNullIndustry) {
      navigate(`/app/companies?${filters}`);
      return;
    }

    navigate(`/app/companies?industry=${JSON.stringify([encodeURIComponent(industryName || '')])}&${filters}`);
  };

  const fetchImage = async () => {
    try {
      if (!industryName) return;
      const industryIcon = industries.find((industry) => industry.name === industryName)?.icon;
      const response = await import(`../assets/images/icons/industries/${industryIcon}.png`); // change relative path to suit your needs
      setCurrentIndustryIcon(response.default);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setHasNotSavedCompanies(numberOfSavedCompanies < NUMBER_OF_COMPANIES_WITH_PULSE_ANIMATION);
  }, [numberOfSavedCompanies]);

  useEffect(() => {
    setIsNullIndustry(!industryName || industryName === 'Featured');
    fetchImage();
    setFilteredCategories([]);
    getFilteredCompaniesFromAPI();
  }, [industryName]);

  useEffect(() => {
    setNumberOfSavedCompanies(applications.length + archivedCompanies.length);
    if ((applications.length + archivedCompanies.length) < NUMBER_OF_COMPANIES_WITH_PULSE_ANIMATION) {
      setHasNotSavedCompanies(true);
    }
  }, [applications.length, archivedCompanies.length]);

  useEffect(() => {
    if (isUniversityLoading) {
      return;
    }

    checkUniversity();
  }, [isUniversityLoading, university]);

  return (
    <div className="explore-companies-page" id="main">
      <div className="explore-companies-page__header">
        <div className="explore-companies-page__header-top">
          <h1 className="explore-companies-page__title">
            {isNullIndustry ? 'Companies that hire people like you...' : (
              <>
                {currentIndustryIcon && <img src={currentIndustryIcon} alt={industryName} className="explore-companies-page__industry-image" />}
                <span>{industryName}</span>
              </>
            ) }
          </h1>
          <Button
            handleClick={handleGoToAdvancedSearch}
            mode="primary"
            outlined
            size="medium"
            label="Advanced Search"
            icon="bi bi-box-arrow-up-right"
            iconPosition="right"
          />
        </div>
        {showH1BBanner && (
          <div className="explore-companies-page__banner">
            <Banner
              title="Are you an international student trying to land a job in the U.S.?"
              text={(
                <>
                  Looking for employers how sponsor your H1B visa? We have the right resource for you.
                  {' '}
                  <Link to="https://h1bdata.info/topjobs.php" target="_blank"><strong><u>Click here to check it out.</u></strong></Link>
                </>
              )}
              image={USFlag}
              isButtonOutlined
              isClosable
              handleClose={() => setShowH1BBanner(false)}
            />
          </div>
        )}
        <div className="explore-companies-page__filters-container">
          {isMobileDevice && <SearchBar />}
          <CompaniesFilters
            locations={locations}
            sizes={sizes}
            tags={tags}
            handleFiltersChange={() => {}}
            handleSearchParamsChange={handleFiltering}
          />
        </div>
      </div>
      <div className="explore-companies-page__filters">
        <IndustryFilters
          industries={industries}
          selectedIndustry={industryName || 'Featured'}
          isExpanded={isNullIndustry}
          filtersSearchParams=""
        />
      </div>
      {(industryName === 'Financial Services' && showFinancialServicesBanner) && (
        <div className="explore-companies-page__banner">
          <Banner
            title="Are you looking for Banks?"
            text="We have a dedicated section for banking institutions."
            handleButtonClick={() => navigate('/app/companies/explore/Banking')}
            buttonLabel="Explore more"
            isButtonOutlined
            backgroundColor="white"
            buttonIcon="bi bi-arrow-up-right"
            buttonIconPosition="right"
            isClosable
            handleClose={() => setShowFinancialServicesBanner(false)}
          />
        </div>
      )}
      {(industryName === 'Banking' && showBankingBanner) && (
        <div className="explore-companies-page__banner">
          <Banner
            title="Looking for other Financial Services?"
            text="We have a dedicated section for Financial Services companies including Investment Banking and FinTech."
            handleButtonClick={() => navigate('/app/companies/explore/Financial%20Services')}
            buttonLabel="Explore more"
            isButtonOutlined
            backgroundColor="white"
            buttonIcon="bi bi-arrow-up-right"
            buttonIconPosition="right"
            isClosable
            handleClose={() => setShowBankingBanner(false)}
          />
        </div>
      )}
      {isLoading && (
        <>
          <div className="explore-companies-page__widget">
            <CategoryWidgetSkeleton />
          </div>
          <div className="explore-companies-page__widget">
            <CategoryWidgetSkeleton />
          </div>
        </>
      )}
      {/* TODO: error handling on backend not sending any data? */}
      {(filteredCategories.length > 0 && !isLoading) && (
        <>
          {filteredCategories.map((category: Category) => (
            <div className="explore-companies-page__widget">
              <CategoryWidget
                key={category.name}
                title={category.name_with_emoji}
                subtitle={category.summary}
                exploreMoreLink={`/app/companies/filter/category/by/${encodeURIComponent(category.name)}`}
              >
                {category.companies.map((company: Company) => (
                  <CompanyCard
                    key={company.id}
                    id={company.id}
                    companyName={company.name}
                    companyIndustry={company.careeros_industry}
                    officeLocations={company.locations || []}
                    companySize={company.company_size}
                    companyDescription={company.short_description ? company.short_description : company.overview}
                    imageURL={company.logo_url}
                    tags={company.tags}
                    companyApplicationId={applications?.find((application) => application.company_id === company.id)?.id || ''}
                    hasSavedAnimation={hasNotSavedCompanies}
                    setHasNotSavedCompanies={() => setNumberOfSavedCompanies(prevNumber => prevNumber + 1)}
                  />
                ))}
              </CategoryWidget>
            </div>
          ))}
          <Banner
            title="Itching for more?"
            text="Dive deeper into our database and discover more companies."
            handleButtonClick={() => navigate('/app/companies')}
            buttonLabel="See All"
            isButtonOutlined
            backgroundColor="white"
            buttonIcon="bi bi-box-arrow-up-right"
            buttonIconPosition="right"
          />
        </>
      )}
      {(!filteredCategories.length && !isLoading) && (
        <EmptySearchResult
          title="We could not find any results for your search."
          subtitle="We couldn’t quite find companies that match your filters. But don’t worry! You can head to LinkedIn and save any company using our Chrome Extension."
          checkExtension={checkUserExtension}
          exploreMoreRedirectLink="/app/companies"
        />
      )}
    </div>
  );
}
export default CustomWithAuthenticationRequired(ExploreCompaniesPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
