/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
import {
  useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSearchParams } from 'react-router-dom';

import { signInWithCustomToken } from 'firebase/auth';
import {
  Timestamp,
  collection, doc, getDoc, onSnapshot, orderBy, query, where,
} from 'firebase/firestore';
import { useQueryClient } from 'react-query';
import { LinkedinChat } from '../linkedin-chat/linkedin-chat';
import { EmailChat } from '../email-chat/email-chat';
import { FirebaseEmailMessage, FirebaseLinkedinMessage, MessageType } from '../../types/messages';
import {
  getEmails, getFirebaseUserToken, sendEmailToContact, sendMessage,
} from '@/services/api/contact';
import { auth, db } from '@/services/firebase/client';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';
import { useIdentitiesNoCache, useSelf, useSelfUniversity } from '@/services/queries/user';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { LinkedinActions } from '../linkedin-actions/linkedin-actions';
import { EmailActions } from '../email-actions/email-actions';
import { useExtensionMessaging } from '@/services/hooks/use-extension-messaging';
import useConnectEmail from '@/services/hooks/use-connect-email';
import { getNetworkingStatusByMessages } from '@/services/helpers/contact';
import { ConnectionStatus } from '@/domains/core/contact/types';
import { ChatNotAvailable } from '../chat-not-available/chat-not-available';
import { EmailNotAvailable } from '../email-not-available/email-not-available';
import { TrackerContext, TrackerContextType } from '@/domains/core/company';
import { useGetSingleCompany } from '@/services/queries/company';
import './chat-box.scss';
import { stripParagraphs } from '@/services/helpers/messages';
import { InboxContextType, useInboxContext } from '@/pages/context/inbox';
import { ChatBoxLoader } from '../chat-box-loader/chat-box-loader';
import { EmailMessageSkeletonContainer } from '../email-chat-skeleton/email-chat-skeleton-container';
import { LinkedinMessageSkeletonContainer } from '../linkedin-message-skeleton/linkedin-message-skeleton-container';
import { ChatBoxHeader } from '../chat-box-header/chat-box-header';
import { trackerTabs } from '@/domains/core/tracker-data';

type ChatBoxProps = {
  isChatFullWidth: boolean;
  connectionStatus: ConnectionStatus;
  handleWidthToggle: (value: boolean) => void;
  handleSetNetworkingStatus: (status: ConnectionStatus) => void;
  networkingStatus: ConnectionStatus;
  onContactUpdate: () => void;
};

// TODO: this component is too big, needs to be refactored https://thecareeros.atlassian.net/browse/COM-2951
export const ChatBox = ({
  isChatFullWidth,
  handleWidthToggle,
  handleSetNetworkingStatus,
  connectionStatus,
  onContactUpdate,
  networkingStatus,
}: ChatBoxProps) => {
  const { user } = useAuth0();
  const chatRef = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();
  const { currentContact: contact } = useInboxContext() as InboxContextType;
  const { data: identities } = useIdentitiesNoCache();
  const [selectedChat, setSelectedChat] = useState<MessageType>();
  const [linkedinMessages, setLinkedinMessages] = useState<FirebaseLinkedinMessage[]>([]);
  const [emailMessages, setEmailMessages] = useState<FirebaseEmailMessage[]>([]);
  const [isFirstOutreach, setIsFirstOutreach] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState(true);
  const [loadingEmails, setLoadingEmails] = useState(true);
  const [isBoxOpened, setIsBoxOpened] = useState(false);
  const [hasEmailConnected, setHasEmailConnected] = useState(false);
  const [isResponseReceived, setIsResponseReceived] = useState(false);
  const { addToast }: AddToastType = useContext(ToastContext);
  const queryClient = useQueryClient();
  const { openTracker } = useContext(TrackerContext) as TrackerContextType;
  const {
    sendLinkedInMessage,
    updateAllLinkedInConversations,
  } = useExtensionMessaging();
  const { trackEvent } = useAnalytics();
  const { data: self, isLoading: isSelfLoading } = useSelf();
  const { data: university } = useSelfUniversity();
  const { data: company, isLoading: isCompanyLoading } = useGetSingleCompany(contact.company_id);
  const successCallback = () => {
    queryClient.invalidateQueries('identities-no-cache');
    setHasEmailConnected(true);
    trackEvent('Email Inbox connected', user, {
      source: 'Inbox',
    });
  };
  const { handleConnectEmail } = useConnectEmail(() => {
    successCallback();
  });
  const defaultRegenerateData = useMemo(() => ({
    studentName: self?.first_name || '[your name]',
    contactName: contact.first_name,
    universityName: university?.name,
    companyName: company?.name,
    industry: company?.careeros_industry || company?.industry || '[industry]',
    jobTitle: contact.current_position.title || '[job title]',
  }), [self, contact, university, company]);

  const handleChatChange = (value: number) => {
    trackEvent('Chat Changed', user, {
      source: 'Inbox',
    });
    setSelectedChat(value === 0 ? 'linkedin' : 'email');
  };

  const isChatLoading = loadingMessages || isSelfLoading || isCompanyLoading;
  if (!user) {
    return <>Loading user</>;
  }

  const getAllEmailData = async (contactID: string, userID: string) => {
    setLoadingEmails(true);
    setEmailMessages([]);

    // New state to track initial load
    let initialLoad = false;

    try {
      const token: string = await getFirebaseUserToken();
      await signInWithCustomToken(auth, token);
      const docRef = doc(db, 'outlookConversations', `${userID}:${contactID}`);
      const d = await getDoc(docRef);
      if (d.exists()) {
        const messagesRef = collection(
          db,
          'outlookConversations',
          `${userID}:${contactID}`,
          'messages',
        );

        const unsubscribe = onSnapshot(
          query(messagesRef, orderBy('sentDateTime', 'desc')),
          (snapshot) => {
            const messagesFromData: any = [];
            snapshot.forEach((docMessages) => {
              messagesFromData.push(docMessages.data());
            });
            setEmailMessages(messagesFromData);

            // Set initialLoad to true after first snapshot update
            if (!initialLoad) {
              initialLoad = true;
              setLoadingEmails(false);
            }
          },
        );

        return unsubscribe; // Return the unsubscribe function for cleanup
      }
      // If no document is found, set initialLoad to true
      initialLoad = true;
      setLoadingEmails(false);
    } catch (e) {
      addToast(
        {
          type: 'error',
          message: 'Failed to get Email messages',
          additionalMessage: "We're looking into the issue. Please try refreshing the page",
        },
      );
      trackEvent('Toast Error Shown', user, {
        message: 'Failed to get emails from firebase',
        error: e,
        source: 'Inbox',
      });
      setLoadingEmails(false);
      return null;
    }
  };

  const getAllLinkedinData = async (contactID: string, userID: string) => {
    setLoadingMessages(true);

    // New state to track initial load
    let initialLoad = false;

    try {
      const token = await getFirebaseUserToken();
      await signInWithCustomToken(auth, token);

      const setupMessagesListener = async (docRef: any) => {
        const messagesRef = collection(docRef, 'messages');
        const unsubscribeMessages = onSnapshot(
          query(messagesRef, orderBy('createdAt')),
          (snapshot) => {
            const messagesFromData: any = [];
            snapshot.forEach((docMessages) => {
              messagesFromData.push(docMessages.data());
            });
            setLinkedinMessages(messagesFromData);

            // Set initialLoad to true after first snapshot update
            if (!initialLoad) {
              initialLoad = true;
              setLoadingMessages(false);
            }
          },
        );
        return unsubscribeMessages;
      };

      let unsubscribeConversations: any;
      if (contact.chat_id && contact.chat_id !== '00000000-0000-0000-0000-000000000000') {
        const chatDocRef = doc(db, 'linkedInConversations', contact.chat_id);
        const d = await getDoc(chatDocRef);
        if (d.exists()) {
          return await setupMessagesListener(chatDocRef);
        }
        setLoadingMessages(false);
      } else {
        const conversationsRef = collection(db, 'linkedInConversations');
        const q = query(
          conversationsRef,
          where('toContactID', '==', contactID),
          where('userID', '==', userID),
        );
        unsubscribeConversations = onSnapshot(q, async (snapshot) => {
          if (!snapshot.empty) {
            const docRef = snapshot.docs[0].ref;
            unsubscribeConversations(); // Stop listening for new conversations once we found the correct one
            return setupMessagesListener(docRef);
          }
          setLoadingMessages(false);

          // If no conversation is found, set initialLoad to true
          if (!initialLoad) {
            initialLoad = true;
            setLoadingMessages(false);
          }
        });
      }
    } catch (e) {
      addToast({
        type: 'error',
        message: 'Failed to get Linkedin messages',
        additionalMessage: "We're looking into the issue. Please try refreshing the page",
      });
      trackEvent('Toast Error Shown', user, {
        message: 'Failed to get Linkedin messages',
        error: e,
        source: 'Inbox',
      });
      setLoadingMessages(false);
    }
  };

  const handleSendMessage = async (message: string) => {
    const now = new Date();
    const seconds = Math.floor(now.getTime() / 1000);
    const nanoseconds = (now.getTime() % 1000) * 1000000;

    const createdAt = new Timestamp(seconds, nanoseconds);
    if (self) {
      setLinkedinMessages([...linkedinMessages, {
        text: message, fromUserID: self.id, fromContactID: null, createdAt, type: 'pending',
      }]);
      const strippedMessage = stripParagraphs(message);
      try {
        const messageResult = await sendLinkedInMessage(contact.linkedin_urn, strippedMessage);
        sendMessage(contact.id);
        queryClient.invalidateQueries('quests');
        queryClient.invalidateQueries(['contact', contact.id]);
        trackEvent('Message Sent', user, {
          type: 'linkedin',
          source: 'Inbox',
          contactName: contact.career_summary.basics.name,
          companyName: company?.name,
          companyLogo: company?.logo_url,
          contactLogo: contact.career_summary.basics.image,
        });
        return messageResult;
      } catch (e) {
        trackEvent('Linkedin Message Sent Failure', user, {
          type: 'linkedin',
          source: 'Inbox',
        });
      }
    }
  };

  const sendEmail = async (message: string, subject: string, to: string[], originalMessageID?: string | null, handleClose?: () => void) => {
    setLoadingMessages(true);
    if (!self) {
      return false;
    }

    if (!hasEmailConnected || (!identities || identities.length === 0)) {
      handleConnectEmail();
      setLoadingMessages(false);
      return false;
    }

    addToast(
      {
        type: 'success',
        message: 'Success',
        additionalMessage: 'Your email has been sent.',
      },
    );

    const response = await sendEmailToContact(message, subject, contact.id, to[0], originalMessageID || null);
    if (handleClose) {
      handleClose();
    }
    if (!response || response?.error) {
      if (response && response?.error === 'Authorization required') {
        handleConnectEmail();
      }
      trackEvent('Email Sending Failed', user, {
        source: 'Inbox',
      });
      addToast(
        {
          type: 'error',
          message: 'Failed to send an email',
          additionalMessage: "We're looking into the issue. Please try again later or contact us.",
        },
      );

      return false;
    }
    queryClient.invalidateQueries('quests');
    queryClient.invalidateQueries(['contact', contact.id]);
    trackEvent('Message Sent', user, {
      source: 'Inbox',
      type: 'email',
      contactName: contact.career_summary.basics.name,
      companyName: company?.name,
      companyLogo: company?.logo_url,
      contactLogo: contact.career_summary.basics.image,
    });

    try {
      setTimeout(async () => getAllEmailData(contact.id, self.id), 0);
    } catch (e) {
      console.log(e);
    }
    setLoadingMessages(false);
    return true;
  };

  const tryGetLinkedInMessages = async () => {
    try {
      updateAllLinkedInConversations();
    } catch (error) {
      addToast(
        {
          type: 'error',
          message: 'Failed to update Linkedin messages',
          additionalMessage: 'Please make sure that you are logged into Linkedin and the Chrome Extension.',
        },
      );
      trackEvent('Toast Error Shown', user, {
        message: 'Failed to update Linkedin messages',
        error,
        source: 'Inbox',
      });
    }
  };

  const fetchEmails = async () => {
    if (!identities) {
      return;
    }

    if (identities.length > 0) {
      const response = await getEmails(contact.id);
      if (!response || response?.error) {
        if (response && response?.error === 'Authorization required') {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          handleConnectEmail();
        }
        addToast(
          {
            type: 'error',
            message: 'Failed to get emails',
            additionalMessage: "We're looking into the issue. Please try again later or contact us.",
          },
        );
        trackEvent('Toast Error Shown', user, {
          message: 'Failed to get emails',
          error: response.error,
          source: 'Inbox',
        });
      }
    }
  };

  const handleAddEmail = () => {
    handleWidthToggle(true);
    trackEvent('Adding Email Click', user, {
      source: 'Inbox',
    });
  };

  const handleClickOnCompanyName = (tab?: (typeof trackerTabs)[number]) => {
    if (contact.company_id) {
      openTracker(encodeURIComponent(contact.company_id), tab);
    }
  };

  const defineInitialChatBasedOnMessages = () => {
    const lastLinkedInMessage: number | undefined = linkedinMessages[0]?.createdAt.seconds;
    const lastEmailMessageSentAt: number | undefined = emailMessages[0]?.sentDateTime.seconds;

    if (!lastLinkedInMessage && !lastEmailMessageSentAt && contact.email) {
      setSelectedChat('email');
      return;
    }

    if (!lastLinkedInMessage || !lastEmailMessageSentAt) {
      setSelectedChat(lastLinkedInMessage ? 'linkedin' : 'email');
      return;
    }

    if (lastLinkedInMessage > lastEmailMessageSentAt) {
      setSelectedChat('linkedin');
    } else {
      setSelectedChat('email');
    }
  };

  const handleSelectLinkedIn = () => {
    setSelectedChat('linkedin');
  };

  useEffect(() => {
    if (identities && identities.length > 0) {
      setHasEmailConnected(true);
    } else {
      setHasEmailConnected(false);
    }
  }, [identities]);

  useEffect(() => {
    setLinkedinMessages([]);
    setEmailMessages([]);
    if (self && contact.id && self.id) {
      getAllEmailData(contact.id, self.id);
      getAllLinkedinData(contact.id, self.id);
      tryGetLinkedInMessages();
      fetchEmails();
    }
  }, [contact.id, self]);

  useEffect(() => {
    // This will move the scroll to the bottom of the div every time messages change
    if (chatRef.current && selectedChat === 'linkedin') {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    } else if (chatRef.current) {
      chatRef.current.scrollTop = 0;
    }
  }, [linkedinMessages, selectedChat]);

  useEffect(() => {
    if (!loadingEmails && !loadingMessages) {
      const status = getNetworkingStatusByMessages(linkedinMessages, emailMessages);

      setIsFirstOutreach(linkedinMessages.length === 0 && emailMessages.length === 0);

      setIsResponseReceived(status === 'connected');

      handleSetNetworkingStatus(status);
    }
  }, [loadingEmails, loadingMessages, contact.id]);

  useEffect(() => {
    if (!selectedChat && (linkedinMessages.length > 0 || emailMessages.length > 0) && !loadingEmails && !loadingMessages) {
      defineInitialChatBasedOnMessages();
    } else if (!selectedChat && !loadingEmails && !loadingMessages) {
      setSelectedChat(contact.email ? 'email' : 'linkedin');
    }
  }, [linkedinMessages.length, emailMessages.length, loadingEmails, loadingMessages]);

  useEffect(() => {
    if (searchParams.has('view')) {
      setSelectedChat(searchParams.get('view') as MessageType);
    }
  }, [searchParams, contact.id]);

  useEffect(() => {
    setIsResponseReceived(networkingStatus === 'connected');
  }, [networkingStatus]);

  if (isSelfLoading || isCompanyLoading) {
    return <ChatBoxLoader />;
  }

  return (
    <div className="chat-box">
      <div className="chat-box__header">
        <ChatBoxHeader
          selectedChat={selectedChat || 'linkedin'}
          handleWidthToggle={() => handleWidthToggle(!isChatFullWidth)}
          handleChatChange={handleChatChange}
          handleCompanyClick={handleClickOnCompanyName}
          isFullWidth={isChatFullWidth}
          contactName={`${contact.first_name} ${contact.last_name}`}
          contactPosition={contact.current_position.title}
          contactCompany={company?.name || ''}
          contactCompletedQuests={contact.completed_quests}
          contactActiveQuests={contact.active_quests}
          isResponseReceived={isResponseReceived}
          isLoadingMessages={loadingMessages || loadingEmails}
        />
      </div>
      <div className="chat-box__content" ref={chatRef}>
        {(!selectedChat || selectedChat === 'linkedin') && isChatLoading && <LinkedinMessageSkeletonContainer />}
        {selectedChat === 'email' && isChatLoading && <EmailMessageSkeletonContainer />}
        {selectedChat === 'linkedin' && connectionStatus === 'connected' && !loadingMessages && (
          <LinkedinChat
            selfID={self?.id || ''}
            messages={linkedinMessages}
            user={user}
            contact={contact}
          />
        )}
        {selectedChat === 'linkedin' && connectionStatus !== 'connected' && !loadingMessages && (
          <ChatNotAvailable
            onContactUpdate={onContactUpdate}
            handleEmailClick={() => setSelectedChat('email')}
            connectionStatus={connectionStatus}
            industry={company.careeros_industry || company.industry || '[industry]'}
            jobTitle={contact.current_position.title || '[job title]'}
          />
        )}
        {selectedChat === 'email' && !isChatLoading && (!contact.email && !contact.secondary_email) && (
          <EmailNotAvailable
            handleAddEmailClick={handleAddEmail}
            handleLinkedinClick={() => setSelectedChat('linkedin')}
          />
        )}
        {selectedChat === 'email' && !isChatLoading && (contact.email || contact.secondary_email) && (
          <EmailChat
            messages={emailMessages}
            handleSelectLinkedIn={handleSelectLinkedIn}
            setIsBoxOpened={setIsBoxOpened}
            isBoxOpened={isBoxOpened}
            hasEmailConnected={hasEmailConnected}
            handleConnectEmail={handleConnectEmail}
          />
        )}
      </div>
      <div className="chat-box__footer">
        <div>
          {(self && selectedChat === 'linkedin' && connectionStatus === 'connected' && !isChatLoading) && (
            <div className="chat-box__message-input">
              <LinkedinActions
                contactID={contact.id}
                sendMessage={handleSendMessage}
                contactName={contact.career_summary.basics.name}
                isFirstOutreach={!loadingMessages && isFirstOutreach}
                regenerateData={{
                  ...defaultRegenerateData,
                  channel: 'linkedin',
                }}
              />
            </div>
          )}

          {(self && selectedChat === 'email' && !isChatLoading) && (
            <EmailActions
              primaryEmail={contact.email}
              emailAddresses={[contact.email, contact.secondary_email]}
              handleSend={sendEmail}
              contactID={contact.id}
              isFirstOutreach={!loadingEmails && isFirstOutreach}
              contactName={contact.first_name}
              regenerateData={{
                ...defaultRegenerateData,
                channel: 'email',
              }}
              setIsBoxOpened={setIsBoxOpened}
              isBoxOpened={isBoxOpened}
              hasEmailConnected={hasEmailConnected}
              handleConnectEmail={handleConnectEmail}
            />
          )}
        </div>
      </div>
    </div>
  );
};
