import { Rows } from '@/domains/core/company/types';
import { http } from '@/services/api/http';

export const getUniversity = async (): Promise<any> => http.get('/users/self/university');

export const getStudentMessages = async (studentID: string): Promise<any> => http.get(`/linkedin/messages/${studentID}`);

export const getStudentResume = async () => http.get('/users/self/resume');

export const resolveComment = async (commentID: string): Promise<any> => http.patch(`/comments/${commentID}/resolve`, {});

export const putResumeSchema = async (resume: any): Promise<any> => http.put('/users/self', {
  career_summary: resume,
});

export const getImprovedSummary = async (jobId: string, comapnyName: string): Promise<any> => http.post('/users/self/resume/tailor', {
  work_experience_company_name: comapnyName,
  job_id: jobId,
});
export const getQuests = async (): Promise<any> => http.get('/quests');

export const changeQuestStatus = async (questID: string, status: any, questType: string): Promise<any> => http.patch(`/quests/${questID}`, { status, type: questType });

export const getLeaderboard = () => http.get('/leaderboard');

export const getQRLeaderboard = () => http.get('/leaderboard/qr');

export const getStudentByID = (studentID: string) => http.get(`/users/${studentID}`);

export const getStudentComments = (studentID: string) => http.get(`/comments/${studentID}`);

export const getStudentActionPlan = (studentID: string) => http.get(`/action-plan/student/${studentID}`);
export const saveStudentActionPlan = (planA: Rows, planB: Rows, planC: Rows, studentID: string) => http.put(`/action-plan/student/${studentID}`, { planA, planB, planC });

export const getStudentBaseResume = (studentID: string) => http.get(`/resume/base/student/${studentID}`);
export const getStudentTailoredResume = (studentID: string) => http.get(`/resume/tailored/student/${studentID}`);

export const getCombinedResume = async (studentID: string) => {
  const [baseResume, tailoredResume] = await Promise.all([
    getStudentBaseResume(studentID),
    getStudentTailoredResume(studentID),
  ]);

  return [
    ...baseResume?.map((resume: any) => ({ ...resume, type: 'base' })) ?? [],
    ...tailoredResume?.map((resume: any) => ({ ...resume, type: 'tailored' })) ?? [],
  ].sort((a: any, b: any) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());
};

export const getStudentProfile = async (studentID: string) => http.get(`/users/${studentID}`);
