import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import SuccessImage from '@/assets/images/success.jpg';
import { ModalContext } from '@/components/modal/modal-provider';
import {
  InfoModal,
  SuccessModal,
} from '@/domains/generic/modals';
import { ActionsBox } from '@/domains/core/user';
import { Button } from '@/components/button/Button';
import {
  TaskAction,
  TaskNames,
  TaskObject,
} from '@/domains/core/tracker-data';
import LightBulb from '@/assets/images/lightbulb-circle.png';

import './tracker-content.scss';
import { TrackerResources } from '../tracker-resources/tracker-resources';
import { useExtensionMessaging } from '@/services/hooks/use-extension-messaging';
import { Banner } from '@/components/banner/banner';

interface ContentProps {
  archiveCompany: () => void;
  applicationId: any;
  // companyName: string;
  activeState: any;
  // user: User | undefined;
  status: any;
  tasks: TaskObject[];
  handleAction: (action: TaskAction) => void;
  university?: string;
}

export const TrackerContent = ({
  archiveCompany,
  activeState,
  applicationId,
  status,
  handleAction,
  tasks,
  university,
}: ContentProps) => {
  const { checkLinkedInLogin } = useExtensionMessaging();
  const { openModal } = useContext(ModalContext) as any;
  // const [canGoToNextStep, setCanGoToNextStep] = useState(false);
  const navigate = useNavigate();
  const [uncompletedTasks, setUncompletedTasks] = useState(tasks);
  const [isBannerDismssed, setIsBannerDismssed] = useState(false);
  const bannersDismissedInLocalStorage = `bannersDismissed-${applicationId}`;

  const checkIfBannerDismissed = () => {
    const bannersDismissed = localStorage.getItem(bannersDismissedInLocalStorage);

    if (bannersDismissed === null) {
      setIsBannerDismssed(false);
      return;
    }

    const parsedBannersDismissed = JSON.parse(bannersDismissed);
    setIsBannerDismssed(parsedBannersDismissed[status]);
  };

  const handleBannerDismiss = () => {
    setIsBannerDismssed(true);
    localStorage.setItem(bannersDismissedInLocalStorage, JSON.stringify({ [status]: true }));
  };

  const handleReceivedRejection = () => {
    openModal(
      <InfoModal
        title="Thanks for sharing!"
        description="Your entire progress with this company will be archived. But don’t worry. If you want to bring it back onto your Overview Board at any point, you can do that through the Archive page."
        handleButtonClick={archiveCompany}
        buttonLabel="Got it"
      />,
    );
  };

  // const handleDecisionSubmit = (decision: string) => {
  //   closeModal();
  //   if (decision === 'Declined offer') {
  //     handleReceivedRejection();
  //   } else {
  //     openModal(
  //       <SuccessModal
  //         title="Congratulations!"
  //         image={SuccessImage}
  //       >
  //         The sky’s the limit. We wish you a wonderful start to your career!
  //       </SuccessModal>,
  //     );
  //   }
  // };

  // const trackStatusChange = (prevStatus: string, newStatus: string) => {
  //   if (prevStatus === newStatus) {
  //     return;
  //   }

  //   if (newStatus !== 'Company Saved') {
  //     trackEvent(`Company moved to ${newStatus}`, user, { company: companyName });
  //   }

  //   const properties = {
  //     newStatus,
  //     company: companyName,
  //   };

  //   trackEvent('Tracker page: Move to next step', user, properties);
  // };

  // const goToNextStep = () => {
  //   const applicationID = applicationId;

  //   const keys = Object.keys(APIstatuses);
  //   const oldIndex = keys.findIndex((key) => key === status);
  //   const newStatus = keys[oldIndex + 1];
  //   if (newStatus) {
  //     changeApplicationStatus(applicationID, newStatus);
  //     trackStatusChange(status, newStatus);
  //     revalidator.revalidate();
  //     window.scrollTo(0, 0);
  //   }
  // };

  // const proceedToNextStep = () => {
  //   closeModal();
  //   goToNextStep();
  // };

  // const openAreYouSure = () => {
  //   trackEvent('Are you sure modal Shown', user);
  //   openModal(
  //     <WarningModalWithCancelButton
  //       title="Are you sure?"
  //       handleSubmitButtonClick={closeModal}
  //       handleCancelButtonClick={proceedToNextStep}
  //       cancelButtonLabel={activeState.areYouSure.next}
  //       submitButtonLabel={activeState.areYouSure.back}
  //       text={activeState.areYouSure.text}
  //     />,
  //   );
  // };

  // const openDecisionModal = () => {
  //   openModal(
  //     <DecisionModal
  //       title="Share your decision"
  //       decisionOptions={['Accepted offer', 'Declined offer']}
  //       handleDecision={handleDecisionSubmit}
  //     />,
  //   );
  // };

  const updateUncompletedTasks = async () => {
    const completedTasksNames: TaskNames[] = [];

    const isExtensionIstalled = await checkLinkedInLogin();

    if (isExtensionIstalled !== undefined) {
      completedTasksNames.push(TaskNames.extension);
    }

    const newUncompletedTasks = tasks.filter((task) => !completedTasksNames.includes(task.name));

    setUncompletedTasks(newUncompletedTasks);
  };

  useEffect(() => {
    updateUncompletedTasks();
    checkIfBannerDismissed();
  }, [tasks]);

  const goToArhive = () => {
    navigate('/app/archive');
  };

  if (!activeState) {
    return (
      <div className="company-tracker-content">
        <Banner
          title="Company is currently in your archive"
          text="If you want to continue working on this company, you can bring it back to your Overview Board from the Archive page."
          handleButtonClick={goToArhive}
          buttonLabel="Go to Archive"
          isButtonOutlined
        />
      </div>
    );
  }
  return (
    <div className="company-tracker-content">
      {(activeState.info && !isBannerDismssed) && (
        <Banner
          isClosable
          title={activeState.info.title}
          image={LightBulb}
          text={activeState.info.text}
          handleClose={handleBannerDismiss}
          isButtonOutlined
        />
      )}
      <div className="company-tracker-content__actions">
        <ActionsBox
          tasks={uncompletedTasks}
          handleAction={handleAction}
        />
      </div>
      {(activeState.resources && activeState.resources.some((resource: any) => resource.forUniversities.includes(university))) && (
        <TrackerResources university={university} resources={activeState.resources} />
      )}
      {status === 'offer' && (
        <div className="company-tracker-content__buttons">
          <Button
            mode="primary"
            icon="bi bi-emoji-neutral"
            outlined
            label="Got Rejected"
            size="medium"
            handleClick={handleReceivedRejection}
          />
          <Button
            mode="primary"
            label="🎉 I Accepted My Offer! 🎉"
            size="medium"
            handleClick={() => openModal(
              <SuccessModal
                title="Congratulations!"
                image={SuccessImage}
              >
                The sky’s the limit. We wish you a wonderful start to your career!
              </SuccessModal>,
            )}
          />
        </div>
      )}
      {/* {status === 'interviewing' && <Button handleClick={handleReceivedRejection} mode="text" size="medium" label="I have received a rejection" />} */}
    </div>
  );
};
