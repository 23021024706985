/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLoaderData } from 'react-router-dom';
import React, { useState, useEffect, useContext } from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { TabsContent, TabsHeader, withTabs } from '@/components/tabs';
import { Loader } from '@/components/loader/loader';
import {
  Action, Goal, Rows, StudentActionPlanResponse,
} from '@/domains/core/company/types';
import '../styles/overview.scss';
import { router } from '@/main';
import { Button } from '@/components/button/Button';
import CustomWithAuthenticationRequired from '@/pages/auth/custom-protected-route';
import { Input } from '@/components/input/input';
import { Select } from '@/components/select/select';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';
import { saveStudentActionPlan } from '@/services/api/student';

type Props = {
  rowsA: Rows;
  rowsB: Rows;
  rowsC: Rows;
  handleInputChange: (
    plan: string,
    goalId: number,
    field: string,
    actionIndex: number | null,
    actionField: keyof Action | null,
    value: string
  ) => void;
  handleAddAction: (plan: string, goalId: number) => void;
  handleSave: () => void;
  handleTabChange: (tab: string) => void;
  currentPlan: string;
};

const createInitialPlan = (): Rows => ({
  'MBA Actions': [{
    id: 1,
    goalTitle: 'When I complete my MBA',
    goal: '',
    actions: [
      {
        id: Date.now(), action: '', startDate: '', dueDate: '', progress: 'Not Started', roadblocks: '', approach: '', feedback: '', comments: '',
      },
    ],
  }],
  '1 Year Actions': [{
    id: 2,
    goalTitle: 'In one year I will have',
    goal: '',
    actions: [
      {
        id: Date.now(), action: '', startDate: '', dueDate: '', progress: 'Not Started', roadblocks: '', approach: '', feedback: '', comments: '',
      },
    ],
  }],
  '6 Months Actions': [{
    id: 3,
    goalTitle: 'In 6 months  I will have',
    goal: '',
    actions: [
      {
        id: Date.now(), action: '', startDate: '', dueDate: '', progress: 'Not Started', roadblocks: '', approach: '', feedback: '', comments: '',
      },
    ],
  }],
  '1 Month Actions': [{
    id: 4,
    goalTitle: 'In one month I will have',
    goal: '',
    actions: [
      {
        id: Date.now(), action: '', startDate: '', dueDate: '', progress: 'Not Started', roadblocks: '', approach: '', feedback: '', comments: '',
      },
    ],
  }],
});

const DreamPlanTable = ({
  rowsA, rowsB, rowsC, handleInputChange, handleAddAction, handleSave, handleTabChange, currentPlan,
}: Props) => {
  const renderActionRows = (plan: string, actions: Action[], goalId: number) => (
    actions.map((action, index) => (
      <tr key={action.id} className="dream-plan__action-row">
        <td className="dream-plan__cell-inner">
          <Input
            value={action.action}
            height="auto"
            id=""
            label=""
            className="dream-plan__textarea"
            placeholder="Action"
            handleValueChange={(value) => handleInputChange(plan, goalId, 'actions', index, 'action', value)}
          />
        </td>
        <td className="dream-plan__cell-inner">
          <DatePicker
            className="datepicker-input"
            value={action.startDate ? dayjs(action.startDate) : null}
            onChange={(date, dateString) => {
              const isoDate = date ? date.toISOString() : '';
              handleInputChange(plan, goalId, 'actions', index, 'startDate', isoDate);
            }}
            picker="month"
          />
        </td>
        <td className="dream-plan__cell-inner">
          <DatePicker
            className="datepicker-input"
            value={action.dueDate ? dayjs(action.dueDate) : null}
            onChange={(date, dateString) => {
              const isoDate = date ? date.toISOString() : '';
              handleInputChange(plan, goalId, 'actions', index, 'dueDate', isoDate);
            }}
            picker="month"
          />
        </td>
        <td className="dream-plan__cell-inner" width={200}>
          <Select
            value={action.progress}
            label=""
            id=""
            options={['Not Started', 'In Progress', 'Completed']}
            handleValueChange={(value) => handleInputChange(plan, goalId, 'actions', index, 'progress', value)}
          />
        </td>
        <td className="dream-plan__cell-inner">
          <Input
            value={action.roadblocks}
            height="auto"
            label=""
            id=""
            className="dream-plan__textarea"
            placeholder="Roadblocks"
            handleValueChange={(value) => handleInputChange(plan, goalId, 'actions', index, 'roadblocks', value)}
          />
        </td>
        <td className="dream-plan__cell-inner">
          <Input
            value={action.approach}
            height="auto"
            label=""
            id=""
            className="dream-plan__textarea"
            placeholder="Approach to Roadblocks"
            handleValueChange={(value) => handleInputChange(plan, goalId, 'actions', index, 'approach', value)}
          />
        </td>
        <td className="dream-plan__cell-inner dream-plan__cell-inner--feedback">
          <Input
            value={action.feedback}
            height="auto"
            label=""
            id=""
            className="dream-plan__textarea"
            placeholder="Feedback"
            handleValueChange={(value) => handleInputChange(plan, goalId, 'actions', index, 'feedback', value)}
          />
        </td>
        <td className="dream-plan__cell-inner">
          <Input
            value={action.comments}
            height="auto"
            label=""
            id=""
            className="dream-plan__textarea"
            placeholder="Comments"
            handleValueChange={(value) => handleInputChange(plan, goalId, 'actions', index, 'comments', value)}
          />
        </td>
      </tr>
    ))
  );

  const renderPlanContent = (plan: string, rows: Rows) => (
    <div data-tab={plan} style={{ display: currentPlan === plan ? 'block' : 'none' }}>
      <h1 className={`dream-plan__title dream-plan__title--${plan.toLowerCase().charAt(plan.length - 1)}`}>
        {plan}
        :
        {' '}
        {' '}
        {plan === 'Plan A' ? 'Dream' : plan === 'Plan B' ? 'Pragmatic' : 'Backup'}
      </h1>
      <table className="dream-plan__table">
        <thead>
          <tr className="dream-plan__header-row">
            <th className="dream-plan__header-cell">Goal</th>
            <th className="dream-plan__header-cell">Actions</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(rows).map(([goalType, goalRows]) => (
            goalRows.map((row) => (
              <tr key={row.id} className="dream-plan__row">
                <td className="dream-plan__cell dream-plan__cell--goal">
                  <h2>{row.goalTitle}</h2>
                  <Input
                    value={row.goal}
                    height="auto"
                    id={goalType}
                    label=""
                    className="dream-plan__textarea"
                    placeholder="Add goal"
                    handleValueChange={(value) => handleInputChange(plan, row.id, 'goal', null, null, value)}
                  />
                </td>
                <td className="dream-plan__cell dream-plan__cell--actions">
                  <table className="dream-plan__actions-table">
                    <thead>
                      <tr>
                        <th>Action</th>
                        <th>Start Date</th>
                        <th>Due Date</th>
                        <th>Progress</th>
                        <th>Roadblocks</th>
                        <th>Approach to Manage Roadblock</th>
                        <th>Feedback Received</th>
                        <th>Additional Comments</th>
                      </tr>
                    </thead>
                    <tbody>
                      {renderActionRows(plan, row.actions, row.id)}
                    </tbody>
                  </table>
                  <br />
                  <Button
                    icon="bi bi-plus"
                    mode="primary"
                    outlined
                    size="medium"
                    label="Add Another Action"
                    onClick={() => handleAddAction(plan, row.id)}
                  />
                </td>
              </tr>
            ))
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <div className="dream-plan">
      <div className="dream-plan__tabs-header">
        <TabsHeader
          withBorder
          onTabClick={handleTabChange}
          tabs={['Plan A', 'Plan B', 'Plan C']}
          defaultTab="Plan A"
        />
      </div>
      <TabsContent>
        {renderPlanContent('Plan A', rowsA)}
        {renderPlanContent('Plan B', rowsB)}
        {renderPlanContent('Plan C', rowsC)}
      </TabsContent>
      <br />
      <br />
      <div className="dream-plan__tabs-footer">
        <Button icon="bi bi-save" mode="primary" onClick={handleSave} size="large" label="Save Action Plan" />
      </div>
    </div>
  );
};

function ActionPlanPage() {
  const {
    student, planA, planB, planC,
  } = useLoaderData() as StudentActionPlanResponse;
  const [isLoading, setIsLoading] = useState(false);
  const [currentPlan, setCurrentPlan] = useState<string>('Plan A');
  const [rowsA, setRowsA] = useState<Rows>(planA || createInitialPlan());
  const [rowsB, setRowsB] = useState<Rows>(planB || createInitialPlan());
  const [rowsC, setRowsC] = useState<Rows>(planC || createInitialPlan());

  const { addToast }: AddToastType = useContext(ToastContext);

  const backButtonClicked = () => {
    if (window.history.length > 1) {
      window.history.back();
    } else {
      router.navigate('/app/dashboard-advisor');
    }
  };

  const handleInputChange = (
    plan: string,
    goalId: number,
    field: string,
    actionIndex: number | null,
    actionField: keyof Action | keyof Goal | null,
    value: string,
  ) => {
    const setRows = plan === 'Plan A' ? setRowsA : plan === 'Plan B' ? setRowsB : setRowsC;

    setRows((prevRows) => {
      const updatedRows = { ...prevRows };
      const goalEntry = Object.entries(updatedRows).find(([_, goals]) => goals.some(goal => goal.id === goalId));

      if (goalEntry) {
        const [goalType, goals] = goalEntry;
        const goalIndex = goals.findIndex(goal => goal.id === goalId);

        if (goalIndex !== -1) {
          if (field === 'actions' && actionIndex !== null && actionField) {
            updatedRows[goalType][goalIndex].actions[actionIndex] = {
              ...updatedRows[goalType][goalIndex].actions[actionIndex],
              [actionField]: value,
            };
          } else if (field === 'goal') {
            updatedRows[goalType][goalIndex].goal = value;
          } else if (field !== 'actions') {
            updatedRows[goalType][goalIndex] = {
              ...updatedRows[goalType][goalIndex],
              [field]: value,
            };
          }
        }
      }

      return updatedRows;
    });
  };

  const handleAddAction = (plan: string, goalId: number) => {
    const setRows = plan === 'Plan A' ? setRowsA : plan === 'Plan B' ? setRowsB : setRowsC;

    setRows((prevRows) => {
      const updatedRows = { ...prevRows };
      const goalEntry = Object.entries(updatedRows).find(([_, goals]) => goals.some(goal => goal.id === goalId));

      if (goalEntry) {
        const [goalType, goals] = goalEntry;
        const goalIndex = goals.findIndex(goal => goal.id === goalId);

        if (goalIndex !== -1) {
          const newAction: Action = {
            id: Date.now(),
            action: '',
            startDate: '',
            dueDate: '',
            progress: 'Not Started',
            roadblocks: '',
            approach: '',
            feedback: '',
            comments: '',
          };
          updatedRows[goalType][goalIndex].actions.push(newAction);
        }
      }

      return updatedRows;
    });
  };

  const savePlan = async () => {
    const response = await saveStudentActionPlan(rowsA, rowsB, rowsC, student.id);

    if (response && !response.error) {
      addToast({
        type: 'success',
        message: 'Action Plan was updated successfully!',
        additionalMessage: 'You can now revisit this later to make changes.',
      });
    } else {
      addToast({
        type: 'error',
        message: 'An error occurred while updating the action plan.',
      });
    }
  };

  const changeTab = (tab: string) => {
    setCurrentPlan(tab);
  };

  useEffect(() => {
    setIsLoading(!student);
  }, [student]);

  if (isLoading) {
    return (
      <div id="loader-zone">
        <Loader />
      </div>
    );
  }

  return (
    <div className="advisor-overview">
      <div className="advisor-overview__top">
        <Button
          mode="invisible"
          size="medium"
          icon="bi bi-arrow-left"
          label="Back"
          handleClick={backButtonClicked}
        />
      </div>
      <div className="white-wrapper white-wrapper--action-plan">
        <h2 className="advisor-overview__title">
          Action Plan for
          {` ${student.first_name} ${student.last_name}`}
        </h2>
        <div className="advisor-overview__instructions">
          <p>
            The purpose of this exercise is to support you to get even more precise on your action plan. You will document actions, timelines, progress, roadblocks, feedback received, and how you will address them. You should review the plan once a week, and adapt is as you gain more information from your market research and progress in your job search.
          </p>
          <p>Instructions</p>
          <p>
            1. Review your goals (column B) that have been populated from the Goals tab into this plan.
            {' '}
            <br />
            2. List 2-5 actions for each goal.
            {' '}
            <br />
            3. For each action, input the start and due date, progress, roadblocks, approach to manage the roadblocks, feedback received, and any additional comments you have.
            {' '}
            <br />
            4. Review the list once a week. Adapt and update it as you progess in your job search.
            {' '}
            <br />
          </p>
        </div>
        <div className="advisor-overview__dream-plan">
          <DreamPlanTable
            rowsA={rowsA}
            rowsB={rowsB}
            rowsC={rowsC}
            handleInputChange={handleInputChange}
            handleAddAction={handleAddAction}
            handleSave={savePlan}
            handleTabChange={changeTab}
            currentPlan={currentPlan}
          />
        </div>
      </div>
    </div>
  );
}

export default CustomWithAuthenticationRequired(withTabs(ActionPlanPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
}));
