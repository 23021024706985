/* eslint-disable no-restricted-globals */
import {
  useContext, useEffect, useRef, useState,
} from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CompanyRating, TrackerContextType, TrackerContext,
} from '@/domains/core/company';
import { changeApplicationStatus } from '@/services/api/application';
import './kanban-tile.scss';
import { statuses } from '@/domains/core/tracker-data';
import { ModalContext } from '@/components/modal/modal-provider';
import {
  AddComment,
  InfoModal,
} from '../../modals';
import { KanbanTileJob } from './tile-job';
import { Tooltip } from '@/components/tooltip/tooltip';
import { SidebarContext } from '@/components/sidebar/sidebar-provider';
import { ContactsSidebar } from '../../sidebars/contacts-sidebar/contacts-sidebar';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { CommentsSidebar } from '@/domains/generic/';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import { useSelf } from '@/services/queries/user';
import { updateCompanyRating } from '@/services/api/company';
import { Comment, Tile } from '@/domains/core/company/types';
import { useStudentComments } from '@/services/queries/student';
import { TileCta } from '../tile-cta/tile-cta';

interface KanbanTileProps {
  tile: Tile;
  readOnly?: boolean;
}

/**
 * Primary UI component for the kanban board
 */
export const KanbanTile = ({
  tile, readOnly = false,
}: KanbanTileProps) => {
  const navigate = useNavigate();
  const { openTracker } = useContext(TrackerContext) as TrackerContextType;
  const { openSidebar, closeSidebar } = useContext(SidebarContext) as any;
  const [isNameOverflow, setIsNameOverflow] = useState(false);
  const [tileComments, setTileComments] = useState<Comment[]>([]);
  const nameElementRef = useRef<any>(null);
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const { trackEvent } = useAnalytics();
  const [isArchived, setIsArchived] = useState(false);
  const { data: self } = useSelf();
  const { studentID } = useParams() as any;
  const { data: commentsResponse, isLoading } = useStudentComments(studentID);
  const queryClient = useQueryClient();

  const handleContactsIconClicked = () => {
    trackEvent('Overview Board: Contact Icon clicked', {});
    if (readOnly) {
      openSidebar(<ContactsSidebar company={tile} closeSidebar={closeSidebar} userID={studentID} />);
    } else {
      openTracker(tile.company_id, 'Contacts');
    }
  };

  // * Student Overview Board Comments
  const openCommentsSideBar = (hasResolveButton = false) => {
    trackEvent('Overview Board: Comments Icon clicked', {});
    openSidebar(<CommentsSidebar company={tile} comments={tileComments} hasResolveButton={hasResolveButton} closeSidebar={closeSidebar} />);
  };

  const goToTracker = (id: string) => {
    history.replaceState({}, '/app/archive');
    openTracker(id);
  };

  const goToArchive = () => {
    closeModal();
    navigate('/app/archive');
  };

  const archiveCompany = async (rememberAction: boolean) => {
    if (rememberAction) {
      localStorage.setItem('rememberArchiveCompany', 'true');
    }
    closeModal();
    await changeApplicationStatus(tile.id, statuses.Archived, tile.ordering);
    setIsArchived(true);
    await queryClient.invalidateQueries(['archive']);
    await queryClient.invalidateQueries(['applications']);
    await queryClient.invalidateQueries(['applications-no-cache']);
    trackEvent('Overview Board: Company Archive', {});
    openModal(
      <InfoModal
        handleButtonClick={goToArchive}
        title="Company Archived!"
        description="The Company is now archived. You can find it in your Archive List."
        buttonLabel="Go to Archive List"
      />,
    );
  };

  const openAddJobModal = () => {
    history.replaceState({}, '/app/archive');
    openTracker(tile.company_id, 'Jobs');
  };

  const handleCompanyRate = async (rate: number) => {
    await updateCompanyRating(tile.id, rate);

    await queryClient.invalidateQueries(['archive']);
    await queryClient.invalidateQueries(['applications']);
    await queryClient.invalidateQueries(['applications-no-cache']);

    trackEvent('Overview Board: Company Rate emoji clicked', {});
  };

  const openAreYouSure = () => {
    const rememberAction = localStorage.getItem('rememberArchiveCompany');
    if (!rememberAction) {
      openModal(
        <InfoModal
          icon="bi bi-exclamation-triangle-fill"
          handleButtonClick={archiveCompany}
          handleSecondaryButtonClick={closeModal}
          title="Are you sure?"
          description="Archiving will stop your progress with this company and remove it from your Overview Board. It will be moved to your Archive List."
          buttonLabel="Archive"
          secondaryButtonLabel="Cancel"
          rememberText="Remember my choice"
          isButtonDanger
        />,
      );
    } else {
      archiveCompany(false);
    }
  };

  const openAddCommentModal = () => {
    openModal(
      <AddComment id={tile.id} payload={{}} type="company" />,
    );
  };

  const getTileComments = () => {
    const tileCommentsFiltered = commentsResponse.comments?.filter((comment: Comment) => comment.application.id === tile.id);
    setTileComments(tileCommentsFiltered);
  };

  const goToNotesTab = () => {
    trackEvent('Overview Board: Notes Icon clicked', {});
    openTracker(tile.company_id, 'Notes');
  };

  useEffect(() => {
    if (nameElementRef.current) {
      setIsNameOverflow(nameElementRef.current.scrollWidth > nameElementRef.current.clientWidth);
    }
  }, [self?.id]);

  useEffect(() => {
    if (isLoading) return;
    getTileComments();
  }, [commentsResponse, isLoading]);
  return (
    !isArchived ? (
      <div
        className="kanban-tile card"
        onClick={() => !readOnly && goToTracker(tile.company_id)}
      >
        <div onClick={() => !readOnly && goToTracker(tile.company_id)} className="kanban-tile__logo">
          <DefaultLogo
            source={tile.logo_url}
            type="company"
            name={tile.company_name}
            className="kanban-tile__logo__image"
            size={24}
          />
        </div>
        <Tooltip label={tile.company_name} position="bottom-center" disableHoverListener={!isNameOverflow}>
          <h3 ref={nameElementRef} onClick={() => !readOnly && goToTracker(tile.company_id)} className="kanban-tile__name">
            {tile.company_name}
          </h3>
        </Tooltip>
        {!readOnly && (
        <div className="kanban-tile__rating">
          <CompanyRating readOnly={readOnly} handleCompanyRate={handleCompanyRate} currentRate={tile.rating} />
        </div>
        )}
        <p className="kanban-tile__industry">{tile.industry}</p>
        <div className="kanban-tile__jobs">
          {tile.jobs?.length > 0 && tile.jobs.map((job) => (
            <KanbanTileJob key={job.title} title={job.title} url={job.linkedin_url} companyId={tile.company_id} readOnly={readOnly} />
          ))}
        </div>
        {readOnly && (
        <div className="kanban-tile__add-comment">
          <TileCta
            label="Write a comment"
            icon="bi bi-plus-lg"
            handleClick={openAddCommentModal}
          />
        </div>
        )}
        <div className="kanban-tile__actions">
          {!readOnly && (
            <TileCta
              label="View Notes"
              icon="bi bi-sticky"
              handleClick={goToNotesTab}
            />
          )}
          <TileCta
            label={readOnly ? 'View Contacts' : `You have ${tile.count_of_contacts} contact(s) at this company.`}
            icon="bi bi-people"
            handleClick={handleContactsIconClicked}
            counter={tile.count_of_contacts}
          />
          {!readOnly ? (
            <>
              {tileComments?.length > 0 && (
                <TileCta
                  label="See Comments"
                  icon="bi bi-chat-square-text"
                  handleClick={() => openCommentsSideBar(true)}
                  counter={tileComments.length}
                />
              )}
              <TileCta
                label="Save Job Posting"
                icon="bi bi-suitcase-lg"
                handleClick={openAddJobModal}
              />

              <TileCta
                label="Archive Company"
                icon="bi bi-archive"
                handleClick={openAreYouSure}
              />
            </>
          ) : (
            <TileCta
              label="View Comments"
              icon="bi bi-chat-square-text"
              handleClick={() => openCommentsSideBar(false)}
              counter={tileComments?.length || 0}
            />
          )}
        </div>
      </div>
    ) : null
  );
};
