import { useEffect, useMemo, useState } from 'react';
import { FirebaseEmailMessage } from '../../types/messages';
import { EmailThread } from '../email-thread/email-thread';
import { groupEmailMessagedBySubject } from '@/services/helpers/messages';
import { useActiveEmail } from '@/services/hooks/use-active-email';
import { EmailThreadPreview } from '../email-thread-preview/email-thread-preview';
import { Button } from '@/components/button/Button';
import './email-chat.scss';

type EmailChatProps = {
  messages: FirebaseEmailMessage[];
  isBoxOpened: boolean;
  hasEmailConnected: boolean;
  setIsBoxOpened: (value: boolean) => void;
  handleSelectLinkedIn: () => void;
  handleConnectEmail: () => void;
};

export const EmailChat = ({
  messages,
  isBoxOpened,
  hasEmailConnected,
  setIsBoxOpened,
  handleSelectLinkedIn,
  handleConnectEmail,
}: EmailChatProps) => {
  const activeEmail = useActiveEmail();
  const groupedMessages = useMemo(
    () => groupEmailMessagedBySubject(messages, activeEmail),
    [messages, activeEmail],
  );
  const [currentThread, setCurrentThread] = useState<string | null>(null);

  const handleSelectThread = (id: string) => {
    setCurrentThread(id);
  };

  const handleEmailClick = () => {
    if (hasEmailConnected) {
      setIsBoxOpened(!isBoxOpened);
    } else {
      handleConnectEmail();
    }
  };

  useEffect(() => {
    setCurrentThread(null);
  }, [messages]);

  if (messages.length === 0) {
    return (
      <div className="email-chat__empty">
        <div className="email-chat__empty-container">
          <h2 className="email-chat__empty-title">
            You don&apos;t have an email history with this contact yet
          </h2>
          <div className="email-chat__empty-actions">
            <Button
              onClick={() => handleSelectLinkedIn()}
              mode="primary"
              size="medium"
              label="Send LinkedIn Message"
              icon="bi bi-linkedin"
              outlined
            />
            <Button
              onClick={handleEmailClick}
              mode="primary"
              size="medium"
              label="Send an Email"
              icon="bi bi-envelope"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`email-chat ${currentThread ? 'email-chat--white-background' : ''}`}>
      {currentThread && (
        <EmailThread
          messages={[...groupedMessages[currentThread]?.messages || []].reverse()}
          threadData={groupedMessages[currentThread]?.data}
          handleCloseThread={() => setCurrentThread(null)}
        />
      )}
      {groupedMessages && !currentThread
          && Object.entries(groupedMessages).map(([id, thread]) => (
            <EmailThreadPreview
              key={id}
              messagesCount={thread.messages.length}
              threadData={thread.data}
              handleSelectThread={() => handleSelectThread(id)}
            />
          ))}
    </div>
  );
};
