import { InboxSidebarEmpty } from '../sidebar-empty-state/sidebar-empty-state';
import { ChatBoxEmpty } from '../chat-box-empty-state/chat-box-empty-state';

type Props = {
  hasExtensionInstalled: boolean;
  hasSavedContacts: boolean;
  setupInterval: any;
};

export const InboxOnboarding = ({
  hasExtensionInstalled,
  hasSavedContacts,
  setupInterval,
}: Props) => (
  <div className="messages-inbox messages-inbox--closed">
    <div className="messages-inbox__sidebar">
      <InboxSidebarEmpty
        setInterval={setupInterval}
        hasExtension={hasExtensionInstalled}
        hasSavedContacts={hasSavedContacts}
      />
    </div>
    <div className="messages-inbox__content messages-inbox__content--inactive">
      <ChatBoxEmpty />
    </div>
  </div>
);
