import { useParams, useRevalidator } from 'react-router-dom';

import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  InboxContactsList, ClickOnConversationCTA, ChatBox,
} from '@/domains/core/netwoking';
import './styles/inbox.scss';

import { useGetContact, useGetContactsNoCache } from '@/services/queries/contact';
import { ConnectionStatus } from '@/domains/core/contact/types';
import { useExtensionMessaging } from '@/services/hooks/use-extension-messaging';

import { fetchAllContactEmails, updateContactConnectionStatus } from '@/services/api/contact';
import { useAnalytics } from '@/services/hooks/use-analytics';
import CustomWithAuthenticationRequired from './auth/custom-protected-route';
import { InboxProvider } from './context/inbox';
import { ContactBioSidebar, InboxOnboarding } from '@/domains/core/netwoking/components';
import { Loader } from '@/components/loader/loader';
import { ChatBoxLoader } from '@/domains/core/netwoking/components/chat-box-loader/chat-box-loader';

const MessagesInbox = () => {
  const [networkingStatus, setNetworkingStatus] = useState<ConnectionStatus>('notConnected');
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>('notConnected');
  const [isChatFullWidth, setIsChatFullWidth] = useState(false);
  const [isInboxLoading, setIsInboxLoading] = useState(true);
  const [hasExtensionInstalled, setHasExtensionInstalled] = useState(false);
  const [hasSavedContacts, setHasSavedContacts] = useState(false);
  const revalidator = useRevalidator();
  const [firstChatContactId, setFirstChatContactId] = useState<string | undefined>();

  const { checkLinkedInLogin, getContactConnectionStatus } = useExtensionMessaging();

  const { trackEvent } = useAnalytics();

  const queryClient = useQueryClient();

  const { data: contacts, isLoading: areContactsLoading } = useGetContactsNoCache();
  const params = useParams();
  const { contactID } = params;
  const [openedChatContactId, setOpenedChatContactId] = useState<string | undefined>(contactID);

  const { data: contact, isLoading: isContactLoading } = useGetContact(openedChatContactId, {
    enabled: !!openedChatContactId, // Only fetch when a valid contactID is available
  });

  const isSetupComplete = hasExtensionInstalled && hasSavedContacts;

  let pollInterval: NodeJS.Timeout;

  const invalidateContactQuery = () => {
    queryClient.invalidateQueries(['contact', openedChatContactId]);
    revalidator.revalidate();
    trackEvent('Inbox Contact Email Changed');
  };

  const handleToggle = (val: boolean) => {
    setIsChatFullWidth(val);
    trackEvent('View Contact Profile');
  };

  const updateConnectionStatus = async () => {
    if (!contact) {
      setConnectionStatus('notConnected');
      return;
    }

    if (contact?.linkedin_connection_status === 'connected') {
      setConnectionStatus(contact.linkedin_connection_status);
      return;
    }
    try {
      const status = await getContactConnectionStatus(contact.linkedin_url);
      if (!status) {
        setConnectionStatus(contact?.linkedin_connection_status || 'notConnected');
      } else {
        setConnectionStatus(status);
        if (status !== contact.linkedin_connection_status) {
          updateContactConnectionStatus(contact, status);
        }
      }
    } catch (error) {
      setConnectionStatus('notConnected');
    }
  };

  const checkExtensionStatus = async () => {
    const hasExtension = await checkLinkedInLogin();
    setHasExtensionInstalled(hasExtension);
    if (hasExtension) {
      clearInterval(pollInterval);
    }
  };

  const setupInterval = () => {
    pollInterval = setInterval(checkExtensionStatus, 5000);
  };

  const setupInbox = async () => {
    setIsInboxLoading(true);
    await checkExtensionStatus();
    if (contacts && contacts.length > 0) {
      setHasSavedContacts(true);
    } else {
      setHasSavedContacts(false);
    }
    setIsInboxLoading(false);
  };

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      if (pollInterval) clearInterval(pollInterval);
    };
  }, []);

  useEffect(() => {
    if (contact && !isContactLoading) {
      updateConnectionStatus();
    }
  }, [contact, isContactLoading]);

  useEffect(() => {
    if (!areContactsLoading) {
      setupInbox();
    }
  }, [contacts, areContactsLoading]);

  useEffect(() => {
    if (firstChatContactId && !contactID) {
      setOpenedChatContactId(firstChatContactId);
    } else {
      setOpenedChatContactId(contactID);
    }
  }, [firstChatContactId, contactID]);

  useEffect(() => {
    if (isSetupComplete) {
      fetchAllContactEmails();
    }
  }, [hasSavedContacts]);

  if (isInboxLoading) {
    return (
      <div id="loader-zone">
        <Loader />
      </div>
    );
  }

  if (!isSetupComplete) {
    return (
      <InboxOnboarding
        hasExtensionInstalled={hasExtensionInstalled}
        hasSavedContacts={hasSavedContacts}
        setupInterval={setupInterval}
      />
    );
  }

  return (
    <div className={`messages-inbox messages-inbox--${isChatFullWidth ? 'opened' : 'closed'}`}>
      <div className="messages-inbox__sidebar">
        <InboxContactsList contacts={contacts || []} selectedContactId={contact?.id} changeFirstChatContactId={setFirstChatContactId} />
      </div>
      {contact && !isContactLoading && (
        <InboxProvider contact={contact}>
          <div className="messages-inbox__content">
            {openedChatContactId && connectionStatus && !isContactLoading && (
              <ChatBox
                connectionStatus={connectionStatus}
                handleSetNetworkingStatus={setNetworkingStatus}
                isChatFullWidth={isChatFullWidth}
                handleWidthToggle={handleToggle}
                onContactUpdate={invalidateContactQuery}
                networkingStatus={networkingStatus}
              />
            )}
          </div>
          <div className="messages-inbox__contact">
            {contact && openedChatContactId && connectionStatus && (
              <ContactBioSidebar
                networkingStatus={networkingStatus}
                connectionStatus={connectionStatus}
                onEmailChanged={invalidateContactQuery}
              />
            )}
          </div>
        </InboxProvider>

      )}

      {!contact && openedChatContactId && (
        <div className="messages-inbox__content">
          <ChatBoxLoader />
        </div>
      )}

      {!openedChatContactId && (
        <div className="messages-inbox__content">
          <ClickOnConversationCTA />
        </div>
      )}
    </div>
  );
};

export default CustomWithAuthenticationRequired(MessagesInbox);
