import { University } from '@/domains/generic/auth/types';
import PlaceholderLogo from '@/assets/images/university/university-placeholder-logo.png';

export function mapTrialUniversitiesFromStrapiToGeneric(universities: any[]): University[] {
  return universities.map((university) => ({
    name: university?.attributes.name,
    logo: university?.attributes.logo?.data?.attributes.url || PlaceholderLogo,
    isTrial: true,
    id: university.id,
  }));
}

export function mapUniversitiesToGeneric(universities: any[]): University[] {
  return universities.map((university) => ({
    name: university.name,
    id: university.id,
    logo: university.logo_url,
    isTrial: false,
  }));
}
